import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";
import { ClaimsI } from "./types";

const name = "claims";

export const initialState = {
    isLoading: false,
    error: "",
    claims: {},
};

export const createClaim = createAsyncThunk("claims/create", async (data: ClaimsI, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.post(`${baseUrl}/claims`, data, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

const claimSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createClaim.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(createClaim.fulfilled, (state, action) => {
            state.isLoading = false;
            state.claims = action.payload;
        });

        builder.addCase(createClaim.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as string;
        });
    },
});

export default claimSlice;
