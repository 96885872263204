import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { Surgery } from "./types";
import surgeryServiceApi from "../surgeryServiceApi";

const name = "surgeries";

const surgeriesAdapter = createEntityAdapter<Surgery>({
    selectId: (surgery) => surgery.surgeryKey,
    sortComparer: (a, b) => (a.startTime < b.startTime ? 1 : -1),
});

export const initialState = surgeriesAdapter.getInitialState<{
    isLoading: boolean;
    hasAttempted: boolean;
}>({ isLoading: false, hasAttempted: false });

export const fetchSurgeries = createAsyncThunk<Array<Surgery>>(
    `${name}/FETCH_SURGERIES`,
    async () =>
        (
            await surgeryServiceApi.get("/v2/surgeries", {
                params: { limit: 20 },
            })
        ).data
);

const surgeriesSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSurgeries.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchSurgeries.fulfilled, (state, { payload }) => {
            surgeriesAdapter.upsertMany(state, payload);
            state.isLoading = false;
            state.hasAttempted = true;
        });
        builder.addCase(fetchSurgeries.rejected, (state) => {
            state.isLoading = false;
            state.hasAttempted = true;
        });
    },
});

export const surgeriesSelectors = surgeriesAdapter.getSelectors();

export default surgeriesSlice;
