import React from "react";
import ReactDOM from "react-dom";

import AppWrapper from "ui/AppWrapper";

// Roboto font
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

ReactDOM.render(React.createElement(AppWrapper), document.getElementById("root"));
