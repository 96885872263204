import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";
import { FetchPhotosParamsI, CreatePhotosBodyI } from "./types";

const name = "photos";

export const initialState = {
    isLoading: false,
    error: "",
    photos: [],
    photosBySurgery: [],
};

export const fetchPhotos = createAsyncThunk("photos/fetch", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/photos`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const fetchPhotosBySurgery = createAsyncThunk(
    "photos/fetchSurgery",
    async (data: FetchPhotosParamsI, thunkApi) => {
        try {
            const config = await tokenHeader(data);
            const resp = await axios.get(`${baseUrl}/photos`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createPhotos = createAsyncThunk(
    "photos/create",
    async (data: CreatePhotosBodyI, thunkApi) => {
        try {
            const config = await tokenHeader(null, true);
            const resp = await axios.post(`${baseUrl}/photos`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deletePhoto = createAsyncThunk("photos/delete", async (id: string, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.delete(`${baseUrl}/photos/${id}`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.response.data.detail);
    }
});

export const updatePhoto = createAsyncThunk(
    "photos/update",
    async (data: CreatePhotosBodyI, thunkApi) => {
        try {
            const id = data.id;
            delete data.id;
            const config = await tokenHeader();
            const resp = await axios.put(`${baseUrl}/photos/${id}`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const isAnyOf = (...matchers: Array<string | { type: string }>) => (action: AnyAction) =>
    matchers.some((matcher) =>
        typeof matcher === "string" ? matcher === action.type : matcher.type === action.type
    );

const photosSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Fetch photos
        builder.addCase(fetchPhotos.fulfilled, (state, action) => {
            state.isLoading = false;
            state.photos = action.payload;
        });

        //Fetch photos by surgery
        builder.addCase(fetchPhotosBySurgery.fulfilled, (state, action) => {
            state.isLoading = false;
            state.photosBySurgery = action.payload;
        });

        //Create photo
        builder.addCase(createPhotos.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.bookmarks = [action.payload, ...state.bookmarks];
            console.log(action.payload, "action.payload");
        });

        //Delete photo by id
        builder.addCase(deletePhoto.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        //Update photo
        builder.addCase(updatePhoto.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        builder.addMatcher(
            isAnyOf(
                fetchPhotos.pending,
                fetchPhotosBySurgery.pending,
                createPhotos.pending,
                deletePhoto.pending,
                updatePhoto.pending
            ),
            (state) => {
                state.isLoading = true;
            }
        );

        builder.addMatcher(
            isAnyOf(
                fetchPhotos.rejected,
                fetchPhotosBySurgery.rejected,
                createPhotos.rejected,
                deletePhoto.rejected,
                updatePhoto.rejected
            ),
            (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            }
        );
    },
});

export default photosSlice;
