import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";

const name = "settings";

export const initialState = {
    isLoading: false,
    terms: {
        agreement: "",
    },
};

export const fetchTerms = createAsyncThunk("settings/fetchTerms", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/termsandconditions/current`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

const termsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Fetch Terms
        builder.addCase(fetchTerms.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchTerms.fulfilled, (state, action) => {
            state.isLoading = false;
            state.terms = action.payload;
        });
        builder.addCase(fetchTerms.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export default termsSlice;
