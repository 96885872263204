import { useTheme } from "@mui/material";
import getTheme from "mixins/GetTheme";

export const zoomableCardActionArea = () => {
    return {
        "& .MuiAvatar-img": {
            transition: "transform 0.2s ease",
        },
        "&:hover": {
            "& .MuiAvatar-img": {
                transform: "scale(1.1)",
            },
        },
    };
};

export const zoomableCardPaper = () => {
    const theme = useTheme();
    const customTheme = getTheme(theme.palette.mode).custom;
    return {
        transition: "background-color 0.2s ease",
        "& *": {
            transition: "color, transform 0.2s ease",
        },
        "&:hover": {
            "& *": {
                color: customTheme.textOnPrimaryColor,
            },
            bgcolor: theme.palette.primary.main,
            "& .MuiAvatar-img": {
                transform: "scale(1.1)",
            },
        },
    };
};
