import {
    Autocomplete,
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import ModalOutline from ".";
import { Add, Close } from "@mui/icons-material";
import { VariantType, useSnackbar } from "notistack";

export interface TagType {
    id: number;
    name: string;
}

// Get all tags from API
const allTagsFromAPI = [
    {
        id: 1,
        name: "Test",
    },
    {
        id: 2,
        name: "New Tag",
    },
    {
        id: 3,
        name: "Another tag",
    },
];

const EditTags = (props: { tagsObject: any; open: boolean; onClose: any; onSubmit?: any }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { tags = [], id = null } = props.tagsObject;
    const [innerTags, setInnerTags] = React.useState<number[]>(tags);
    const [allTags, setAllTags]: [TagType[], any] = React.useState<TagType[]>([]);
    const [newTag, setNewTag] = React.useState("");
    const [selectedTag, setSelectedTag] = React.useState<TagType | null>(null);
    const maxTagLength = 64;

    // Methods
    const submitModal = () => {
        // Must return `true` to hide the modal
        if (id) {
            // Update
        } else {
            // User has just scanned a QR code and creating assigning tags to it
            if (props.onSubmit) {
                let outerTags = [...allTags];
                outerTags = outerTags.filter((tag) => innerTags.includes(tag.id));
                props.onSubmit(outerTags);
            }
        }
        return true;
    };
    const trickToReRenderComponent = () => {
        const newTagTmp = newTag;
        setNewTag(`${newTagTmp} `);
        setTimeout(() => {
            setNewTag(newTagTmp);
        }, 10);
    };
    const resetTags = () => {
        setInnerTags(() => [...tags]);
    };
    const toggleTag = (id: number) => {
        const newTags = innerTags;
        if (innerTags.includes(id)) {
            const i = newTags.indexOf(id);
            if (i > -1) {
                newTags.splice(i, 1);
            }
        } else {
            newTags.push(id);
        }
        setInnerTags(() => newTags);
        trickToReRenderComponent();
    };
    const pushNewTag = (name: string) => {
        if (name && name.trim().length > 0) {
            const newAllTags = allTags;
            // Create a new tag object by sending it to API to receive a new id
            const newTagObj = {
                id: Math.ceil(Math.random() * 999), // This ID should be recieved from API
                name,
            };
            newAllTags.push(newTagObj);
            setAllTags(() => newAllTags);
            const newTags = innerTags;
            newTags.push(newTagObj.id);
            setInnerTags(() => [...newTags]);
            setNewTag("");
        }
    };
    const getAllTags = () => allTags;

    // Watchers
    React.useEffect(() => {
        setTimeout(() => {
            setAllTags(() => [...allTagsFromAPI]);
            resetTags();
        }, 100);
    }, [open, JSON.stringify(tags)]);
    React.useEffect(() => {
        if (selectedTag?.id) {
            if (!innerTags.includes(selectedTag.id)) {
                toggleTag(selectedTag.id);
            }
            if (allTags.findIndex((tag) => tag.id === selectedTag.id) > -1) {
                enqueueSnackbar("Tag already exists, we've selected it for you", {
                    variant: "info",
                });
            }
        }
    }, [JSON.stringify(selectedTag)]);
    return (
        <ModalOutline
            open={props.open}
            title="Tags"
            cancelable
            submitText="Save"
            width="550"
            onSubmit={() => submitModal()}
            onClose={props.onClose}
            onCancel={() => resetTags()}
        >
            <Box aria-label="tags selection" display={"flex"} gap={1} mb={1} flexWrap={"wrap"}>
                {getAllTags().map((tag, i) => (
                    <Button
                        key={`${i}-${tag.id}`}
                        aria-label={tag.name}
                        sx={{ textTransform: "initial" }}
                        variant={innerTags.includes(tag.id) ? "contained" : "outlined"}
                        disableElevation
                        onClick={() => toggleTag(tag.id)}
                    >
                        {tag.name}
                    </Button>
                ))}
            </Box>
            <Autocomplete
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoFocus
                        label="Add Tag"
                        variant="outlined"
                        helperText={
                            <>
                                <Box display={"flex"}>
                                    <Box flexGrow={1}>
                                        Tags are personal and have a maximum of {maxTagLength}{" "}
                                        characters.
                                        <br />
                                        You can press <code>Enter</code> to add a tag
                                    </Box>
                                    <Box flexShrink={1} minWidth={"35px"}>
                                        {newTag.length} / {maxTagLength}
                                    </Box>
                                </Box>
                            </>
                        }
                        inputProps={{
                            ...params.inputProps,
                            maxlength: maxTagLength,
                            autoComplete: "off",
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => pushNewTag(newTag)}>
                                        <Add />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={newTag}
                        sx={{ "& .MuiInputBase-root": { pr: "12px !important" } }}
                        onChange={(e) => setNewTag(e.target.value)}
                    />
                )}
                autoHighlight
                options={allTags}
                getOptionLabel={(option) => option.name}
                fullWidth
                value={selectedTag}
                noOptionsText={
                    <>
                        Press <code>Enter</code> to add tag
                    </>
                }
                onChange={(event: any, newValue: TagType | null) => {
                    setSelectedTag(newValue);
                }}
                onKeyPress={(e) => e.key === "Enter" && pushNewTag(newTag)}
            ></Autocomplete>
        </ModalOutline>
    );
};

export default EditTags;
