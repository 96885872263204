import { BarChart } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { usePageTitle } from "./../../../hooks/index";
import StatsIconIOS from "ui/resources/svg/stats.svg";
import CustomSvgIcon from "ui/components/Utils/CustomSvgIcon";

const StatsPage: React.FC = () => {
    usePageTitle("Stats");
    return (
        <Grid
            height={"calc(100vh - 185px)"}
            container
            justifyContent={"center"}
            alignItems={"center"}
            maxWidth={"sm"}
            mx={"auto"}
            px={5}
        >
            <Box display={"flex"} flexDirection={"column"} rowGap={3} textAlign={"center"}>
                <Box>
                    <CustomSvgIcon size="8" scale="1.5" src={StatsIconIOS}></CustomSvgIcon>
                </Box>
                <Box>
                    <Typography variant="body1">
                        Your surgery statistics will be shown here.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body1">
                        Statistics are available once you have claimed surgeries from the Logbook
                        and associated telemetry data has been recieved from Versius.
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default StatsPage;
