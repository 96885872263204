import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { fixDurations } from "mixins/Utils";
import ModalOutline from ".";
import { Alarm, Close } from "@mui/icons-material";
import { useTypedDispatch, useTypedSelector } from "../../../hooks/store";
import {
    createBookmark,
    deleteBookmark,
    fetchBookmarks,
    updateBookmark,
} from "../../../state/bookmarks";
import { CreateBookmarkI } from "../../../state/bookmarks/types";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const EditBookmark = (props: { bookmark: any; open: boolean; onClose: any }) => {
    const { title = "", time = "0", id = 0 } = props.bookmark;
    const [innerTitle, setInnerTitle] = React.useState(title);
    const timeString = fixDurations(time);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useTypedDispatch();
    const params = useParams<{ id: string }>();

    const bookmarkData = useTypedSelector((state) => state.bookmarksData);

    React.useEffect(() => {
        setTimeout(() => {
            setInnerTitle(title);
        }, 100);
    }, [title]);

    useEffect(() => {
        dispatch(fetchBookmarks());
    }, []);

    useEffect(() => {
        if (bookmarkData.error) {
            enqueueSnackbar(bookmarkData.error, {
                variant: "error",
            });
        }
    }, [bookmarkData.error]);

    // Methods
    const deleteBookmarkHand = () => {
        dispatch(deleteBookmark("1"));
        // Must return `true` to hide the modal
        return true;
    };
    const submitModal = async () => {
        // Must return `true` to hide the modal
        if (id) {
            // Update existing
            const data = {
                time: time.toString(),
                title: innerTitle,
                video_id: params.id,
                bookmarkId: "1",
            } as CreateBookmarkI;
            dispatch(updateBookmark(data));
        } else {
            // Add new
            const data = {
                time: time.toString(),
                title: innerTitle,
                video_id: params.id,
            } as CreateBookmarkI;

            dispatch(createBookmark(data));
            setInnerTitle("");
        }
        return true;
    };

    return (
        <ModalOutline
            open={props.open}
            title={`${id ? "Update" : "New"} bookmark`}
            cancelable
            submitText="Save"
            thirdAction={id ? true : false}
            thirdActionText="Delete bookmark"
            thirdActionColor="error"
            width="550"
            onThirdAction={() => deleteBookmarkHand()}
            onSubmit={() => submitModal()}
            onClose={props.onClose}
        >
            <TextField
                value={timeString}
                label="Time"
                aria-readonly
                disabled
                variant="outlined"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Alarm />
                        </InputAdornment>
                    ),
                }}
            ></TextField>
            <TextField
                autoFocus
                value={innerTitle}
                onChange={(e) => setInnerTitle(e.target.value)}
                label="Title"
                helperText="Write title for your bookmark"
                variant="outlined"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setInnerTitle("")}>
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            ></TextField>
        </ModalOutline>
    );
};

export default EditBookmark;
