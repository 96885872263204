import {
    Autocomplete,
    Box,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import useIsBreakpoint from "hooks/useIsBreakpoint";
import React from "react";
import ModalOutline from ".";

interface ProcedureType {
    id: string;
    name: string;
}
interface SurgeonRoleType {
    // We can't reuse `ProcedureType` because it may vary in real API
    id: string;
    name: string;
}
export interface ProcedureRoleType {
    procedure: ProcedureType;
    role: SurgeonRoleType;
    id: number;
    index: number | null;
}

const proceduresFromAPI: readonly ProcedureType[] = [
    {
        id: "1",
        name: "Proc One",
    },
    {
        id: "2",
        name: "Proc Two",
    },
    {
        id: "3",
        name: "Proc Three",
    },
];

// Surgeon roles
const surgeonRoles = [
    {
        id: "console-surgeon",
        name: "Console Surgeon",
    },
    {
        id: "bedside-surgeon",
        name: "Bedside Surgeon",
    },
    {
        id: "other",
        name: "Other",
    },
];

const EditProcedure = (props: {
    procedureObject: any;
    open: boolean;
    onClose: any;
    onSubmit?: any;
    onDelete?: any;
}) => {
    const { procedure = { name: "", id: "" }, role = { name: "", id: "" }, index = null, id = 0 } =
        props.procedureObject || {};
    const [innerProcedure, setInnerProcedure] = React.useState<ProcedureType | null>(procedure);
    const [innerRole, setInnerRole] = React.useState<SurgeonRoleType | null>(role);
    const [innerRoleId, setInnerRoleId] = React.useState<string | null>(role?.id || null);
    const isSmAndDown = useIsBreakpoint("sm", "<");

    const setInitValues = () => {
        setInnerProcedure(procedure || null);
        setInnerRole(role || null);
        setInnerRoleId(role?.id || null);
    };

    React.useEffect(() => {
        const role = surgeonRoles.find((r) => r.id === innerRoleId) || null;
        setInnerRole(role);
    }, [innerRoleId]);

    React.useEffect(() => {
        setTimeout(() => {
            console.log("init");
            setInitValues();
        }, 100);
    }, [props.procedureObject, open]);

    // Methods
    const submitModal = () => {
        // Must return `true` to hide the modal
        if (id) {
            // Update
        } else {
            // User has just scanned a QR code and creating assigning a procedure to it
            props.onSubmit && props.onSubmit({ procedure: innerProcedure, role: innerRole, index });
        }
        setInitValues();
        return true;
    };
    const onDelete = () => {
        props.onDelete && props.onDelete(index);
        return true;
    };
    return (
        <ModalOutline
            open={props.open}
            title="Procedure"
            cancelable
            submitText="Save"
            width="550"
            disableSubmit={!innerProcedure || !innerProcedure.id || !innerRole || !innerRole.id}
            thirdAction
            thirdActionColor="error"
            thirdActionText="Delete"
            onSubmit={() => submitModal()}
            onClose={props.onClose}
            onCancel={() => setInitValues()}
            onThirdAction={() => onDelete()}
        >
            <Autocomplete
                renderInput={(params) => (
                    <TextField
                        {...params}
                        autoFocus
                        label="Procedure"
                        variant="outlined"
                        helperText="Start typing procedure name or code"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "off",
                        }}
                        InputProps={{
                            ...params.InputProps,
                        }}
                    ></TextField>
                )}
                autoHighlight
                options={proceduresFromAPI}
                getOptionLabel={(option) => option.name}
                value={innerProcedure}
                onChange={(event: any, newValue: ProcedureType | null) => {
                    setInnerProcedure(newValue);
                }}
                fullWidth
            ></Autocomplete>
            <Box display={"flex"} rowGap={2} flexDirection={"column"}>
                <Typography variant="body1">My role</Typography>
                <Box>
                    <ToggleButtonGroup
                        size={isSmAndDown ? "small" : "large"}
                        color="primary"
                        value={innerRoleId}
                        exclusive
                        aria-label="select role"
                        aria-labelledby="id"
                        onChange={(e, v) => setInnerRoleId(v)}
                    >
                        {surgeonRoles.map((role, i) => (
                            <ToggleButton
                                key={i}
                                sx={{ textTransform: "initial", px: 3 }}
                                value={role.id}
                                aria-label={role.name}
                            >
                                {role.name}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
            </Box>
        </ModalOutline>
    );
};

export default EditProcedure;
