import React from "react";

import { usePageTitle } from "hooks";
import { useSurgeries } from "hooks/surgeries";
import LoadingIndicator from "ui/components/LoadingIndicator";
import SurgeryList from "ui/components/SurgeryList";

const SurgeryListPage: React.FC = () => {
    usePageTitle("Surgery List");
    const [surgeries, { isLoading }] = useSurgeries();

    return (
        <>
            <h3>Surgeries:</h3>
            {isLoading ? <LoadingIndicator /> : <SurgeryList surgeries={surgeries} />}
        </>
    );
};

export default SurgeryListPage;
