import { Edit } from "@mui/icons-material";
import { Box, Card, List, ListItemButton, ListItemText, useTheme } from "@mui/material";
import { usePageTitle } from "hooks";
import { Link } from "react-router-dom";
import React from "react";

const MyProfile: React.FC = () => {
    usePageTitle("My Profile");
    const theme = useTheme();
    const listItems = [
        {
            secondaryText: "EMAIL",
            primaryText: "testuserpr@gmail.com",
            to: null,
        },
        {
            secondaryText: "PASSWORD",
            primaryText: "- - - -",
            to: "change-password",
        },
    ];
    return (
        <Box maxWidth={"sm"} mx={"auto"}>
            <Card sx={{ mb: 2 }}>
                <List component="nav">
                    {listItems.map((item, i) => (
                        <ListItemButton
                            key={i}
                            component={item.to ? Link : "div"}
                            to={`/settings/my-profile/${item.to}`}
                        >
                            <ListItemText
                                primary={item.primaryText}
                                secondary={item.secondaryText}
                                secondaryTypographyProps={{ style: { fontSize: 12 } }}
                                sx={{ display: "flex", flexFlow: "column-reverse" }}
                            />
                            {item.to && <Edit color="primary" />}
                        </ListItemButton>
                    ))}
                </List>
            </Card>
        </Box>
    );
};

export default MyProfile;
