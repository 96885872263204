import React from "react";
import { Link } from "react-router-dom";

import SurgeryListItem from "./SurgeryListItem";
import { Surgery } from "state/surgeries/types";

export interface SurgeryListProps {
    surgeries: Array<Surgery>;
}
const SurgeryList: React.FC<SurgeryListProps> = ({ surgeries }) => (
    <ul>
        {surgeries.length
            ? surgeries.map((s) => (
                  <li key={s.surgeryKey}>
                      <Link to={`/surgeries/${s.surgeryKey}`}>
                          <SurgeryListItem surgery={s} />
                      </Link>
                  </li>
              ))
            : "No surgeries to show"}
    </ul>
);

export default SurgeryList;
