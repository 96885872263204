import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";
import { Surgery } from "./types";

const name = "surgeries";

export const initialState = {
    isLoading: false,
    error: "",
    surgeries: [],
    surgery: {},
};

export const fetchSurgeries = createAsyncThunk("surgeries/fetch", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/surgeries`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const fetchSurgeriesById = createAsyncThunk(
    "surgeries/fetchId",
    async (id: string, thunkApi) => {
        try {
            const config = await tokenHeader();
            const resp = await axios.get(`${baseUrl}/surgeries/${id}`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createSurgeries = createAsyncThunk(
    "surgeries/create",
    async (data: Surgery, thunkApi) => {
        try {
            console.log(data, "data");
            const config = await tokenHeader();
            const resp = await axios.post(`${baseUrl}/surgeries`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const updateSurgeries = createAsyncThunk(
    "surgeries/update",
    async (data: Surgery, thunkApi) => {
        try {
            const id = data.id;
            delete data.id;
            const config = await tokenHeader();
            const resp = await axios.put(`${baseUrl}/surgeries/${id}`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const isAnyOf = (...matchers: Array<string | { type: string }>) => (action: AnyAction) =>
    matchers.some((matcher) =>
        typeof matcher === "string" ? matcher === action.type : matcher.type === action.type
    );

const surgeriesSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Fetch surgeries
        builder.addCase(fetchSurgeries.fulfilled, (state, action) => {
            state.isLoading = false;
            state.surgeries = action.payload;
        });

        //Fetch surgeries by id
        builder.addCase(fetchSurgeriesById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.surgery = action.payload;
        });

        //Create surgeries
        builder.addCase(createSurgeries.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.surgeries = [...state.surgeries,action.payload];
        });

        //Update surgeries
        builder.addCase(updateSurgeries.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.surgeries = [...state.surgeries,action.payload];
        });

        builder.addMatcher(
            isAnyOf(
                fetchSurgeriesById.pending,
                fetchSurgeries.pending,
                createSurgeries.pending,
                updateSurgeries.pending
            ),
            (state) => {
                state.isLoading = true;
            }
        );

        builder.addMatcher(
            isAnyOf(
                fetchSurgeriesById.rejected,
                fetchSurgeries.rejected,
                createSurgeries.rejected,
                updateSurgeries.rejected
            ),
            (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            }
        );
    },
});

export default surgeriesSlice;
