import { useEffect } from "react";

import { fetchSurgeries, surgeriesSelectors } from "state/surgeries";
import { useTypedSelector, useTypedDispatch } from "hooks/store";
import { Surgery } from "state/surgeries/types";

export const useSurgeries = (): [
    // Actual data
    Array<Surgery>,
    // Additional utilities
    {
        isLoading: boolean;
    }
] => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector((state) => state.surgeries.isLoading);
    const hasAttempted = useTypedSelector((state) => state.surgeries.hasAttempted);
    const surgeries = useTypedSelector((state) => surgeriesSelectors.selectAll(state.surgeries));

    // Fetch surgeries if they have not already been fetched
    useEffect(() => {
        if (!isLoading && !hasAttempted) {
            dispatch(fetchSurgeries());
        }
    }, []); // Triggered on load

    return [
        surgeries,
        {
            isLoading,
        },
    ];
};
