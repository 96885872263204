import { useEffect } from "react";
import { useTypedDispatch } from "hooks/store";
import { updatePageTitle, handleDrawerState } from "state/appSettings";

export const usePageTitle = (title: string): void => {
    const dispatch = useTypedDispatch();
    useEffect(() => {
        dispatch(updatePageTitle(title));
        document.title = `CMR TODO RENAME - ${title}`;
    }, []);
};

export const useDrawerState = (state: boolean | undefined): void => {
    const dispatch = useTypedDispatch();
    useEffect(() => {
        dispatch(handleDrawerState(state));
    }, []);
};
