import React from "react";
import { usePageTitle } from "./../../../../hooks";
import { Link, useParams } from "react-router-dom";
import {
    Avatar,
    Card,
    CardActionArea,
    Chip,
    IconButton,
    ImageListItem,
    ImageListItemBar,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import {
    Edit,
    GroupsOutlined,
    HelpOutline,
    LocalOfferOutlined,
    OndemandVideoOutlined,
    SpeakerNotesOutlined,
    TagOutlined,
} from "@mui/icons-material";
import { fixDurations } from "mixins/Utils";
import EditTitle from "ui/components/Modals/EditTitle";
import EditNotes from "ui/components/Modals/EditNotes";
import EditTags from "ui/components/Modals/EditTags";
import InfoModal, { DataObjectType } from "../../../components/Modals/InfoModal";
import getTheme from "mixins/GetTheme";

const VideoLibraryItemDetails = () => {
    const { id, showVideoCard } = useParams<{ id: string; showVideoCard: string }>();
    const canShowVideo = showVideoCard === "show-video-card";
    const item = {
        // TODO Ask about the correct keys:value-types of the object
        // This object will be fetched from API
        id,
        name: "Dry Lab",
        date: "Aug 18, 2020",
        time: "01:20:57 PM",
        duration: "3420", // Seconds
        hospital: "EBP - Pod (Upstairs)",
        type: "Research",
        title: "Intern Experience",
        video: {
            thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
            elapsedTime: "522", // Seconds
        },
        participants: [
            {
                id: 1,
                name: "Test UserPR",
                avatar: "https://lwlies.com/wp-content/uploads/2017/04/avatar-2009.jpg",
            },
            {
                id: 2,
                name: "David Hull",
                avatar: "",
            },
        ],
        tags: [
            {
                id: 1,
                name: "Test",
            },
            {
                id: 2,
                name: "New Tag",
            },
        ],
        notes:
            "Magna minim fugiat officia ea deserunt consectetur magna reprehenderit amet. Elit sunt ea exercitation velit occaecat velit dolor officia do laboris enim veniam. Exercitation excepteur pariatur esse ex ipsum tempor adipisicing et duis tempor culpa. Pariatur deserunt ut eu esse incididunt excepteur cillum fugiat ullamco ut aute deserunt. Irure aliqua labore irure esse occaecat fugiat. Fugiat mollit sit consequat consequat consequat.",
    };
    item.duration = fixDurations(item.duration);
    item.video.elapsedTime = fixDurations(item.video.elapsedTime);
    usePageTitle(item.name);
    const theme = useTheme();
    const customTheme = getTheme(theme.palette.mode).custom;

    // Handle Title Update
    const [titleModalOpen, setTitleModalOpen] = React.useState(false);
    const [notesModalOpen, setNotesModalOpen] = React.useState(false);
    const [infoModalOpen, setInfoModalOpen] = React.useState(false);
    const [infoModalData, setInfoModalData] = React.useState<DataObjectType>({});
    const [tagsModalOpen, setTagsModalOpen] = React.useState(false);
    // ../Handle Title Update

    // Info Modals
    const openInfoModal = (obj: any) => {
        setInfoModalData(obj);
        setInfoModalOpen(true);
    };
    // ../End Info Modals
    return (
        <div>
            <Box display={"flex"} flexDirection={"column"} rowGap={2} mx={"auto"} maxWidth={"sm"}>
                {/* Data and Time + Hospital + Duration + Type */}
                <Card>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        rowGap={2}
                        paddingX={2}
                        paddingY={2}
                    >
                        <Box display={"flex"}>
                            <Box flexGrow={1}>
                                <Typography variant="overline" color={theme.palette.text.secondary}>
                                    Date and Time
                                </Typography>
                                <Typography variant="body2">
                                    {item.date}, {item.time}
                                </Typography>
                            </Box>
                            <Box flexShrink={1}>
                                <IconButton
                                    onClick={() =>
                                        openInfoModal({
                                            title: "Not yet available",
                                            text:
                                                "The server is waiting to recieve location and duration information",
                                        })
                                    }
                                >
                                    <HelpOutline></HelpOutline>
                                </IconButton>
                            </Box>
                        </Box>
                        <Box display={"flex"}>
                            <Box flexGrow={1}>
                                <Typography variant="overline" color={theme.palette.text.secondary}>
                                    Hospital
                                </Typography>
                                <Typography variant="body2">{item.hospital}</Typography>
                            </Box>
                            <Box flexShrink={1}>
                                <Typography variant="overline" color={theme.palette.text.secondary}>
                                    Duration
                                </Typography>
                                <Typography sx={{ textAlign: "end" }} variant="body2">
                                    {item.duration}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display={"flex"} justifyContent="flex-end">
                            <Box flexShrink={1}>
                                <Typography color={customTheme.secondaryText} variant="body2">
                                    {item.type}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Card>
                {/* Title */}
                <Card>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        rowGap={2}
                        paddingX={2}
                        paddingY={2}
                    >
                        <Box display={"flex"}>
                            <Box flexGrow={1}>
                                <Typography variant="overline" color={theme.palette.text.secondary}>
                                    Title
                                </Typography>
                                <Typography variant="body2">{item.title}</Typography>
                            </Box>
                            <Box flexShrink={1}>
                                <IconButton onClick={() => setTitleModalOpen(true)}>
                                    <Edit></Edit>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Card>
                {/* Video */}
                {canShowVideo && (
                    <Card>
                        <Box display={"flex"} flexDirection={"column"} paddingX={2} paddingY={2}>
                            <Avatar
                                sx={{
                                    bgcolor: "transparent",
                                    color: (theme) => theme.palette.text.disabled,
                                }}
                            >
                                <OndemandVideoOutlined fontSize="large"></OndemandVideoOutlined>
                            </Avatar>
                            <Box display={"flex"} flexDirection={"column"} rowGap={2}>
                                <Typography variant="overline" color={theme.palette.text.secondary}>
                                    Surgery video complete
                                </Typography>
                                <CardActionArea>
                                    <Link to={`/video-library/${item.id}`}>
                                        <Box>
                                            <ImageListItem sx={{ width: "100%" }}>
                                                <Avatar
                                                    style={{ width: "100%", height: 200 }}
                                                    variant="square"
                                                    src={item.video.thumbnail}
                                                ></Avatar>
                                                <ImageListItemBar
                                                    title="You've watched"
                                                    subtitle={`${item.video.elapsedTime} from this video`}
                                                ></ImageListItemBar>
                                            </ImageListItem>
                                        </Box>
                                    </Link>
                                </CardActionArea>
                            </Box>
                        </Box>
                    </Card>
                )}
                {/* Participants */}
                <Card>
                    <Box display={"flex"} flexDirection={"column"} paddingX={2} paddingY={2}>
                        <Avatar
                            sx={{
                                bgcolor: "transparent",
                                color: (theme) => theme.palette.text.disabled,
                            }}
                        >
                            <GroupsOutlined fontSize="large"></GroupsOutlined>
                        </Avatar>
                        <Box display={"flex"}>
                            <Box flexGrow={1}>
                                <Typography variant="overline" color={theme.palette.text.secondary}>
                                    Participants
                                </Typography>
                                <Box display={"flex"} columnGap={1}>
                                    {item.participants.map((user, i) => (
                                        <Chip
                                            key={i}
                                            label={user.name}
                                            variant="outlined"
                                            sx={{ borderColor: theme.palette.divider }}
                                            avatar={
                                                <Avatar
                                                    alt={user.name}
                                                    src={user.avatar ? user.avatar : ""}
                                                    sx={{
                                                        bgcolor: theme.palette.background.default,
                                                    }}
                                                >
                                                    {!user.avatar && user.name.substring(0, 1)}
                                                </Avatar>
                                            }
                                        ></Chip>
                                    ))}
                                </Box>
                            </Box>
                            <Box flexShrink={1}>
                                <IconButton
                                    onClick={() =>
                                        openInfoModal({
                                            title: "Participants",
                                            text:
                                                "Participants are added here when they claim the surgery",
                                        })
                                    }
                                >
                                    <HelpOutline></HelpOutline>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Card>
                {/* Tags */}
                <Card>
                    <Box display={"flex"} flexDirection={"column"} paddingX={2} paddingY={2}>
                        <Avatar
                            sx={{
                                bgcolor: "transparent",
                                color: (theme) => theme.palette.text.disabled,
                                transform: "translateX(-5px)",
                            }}
                        >
                            <TagOutlined fontSize="large"></TagOutlined>
                        </Avatar>
                        <Box display={"flex"}>
                            <Box flexGrow={1}>
                                <Typography variant="overline" color={theme.palette.text.secondary}>
                                    Tags
                                </Typography>
                                <Box display={"flex"} columnGap={1}>
                                    <Chip
                                        icon={<LocalOfferOutlined />}
                                        label={item.tags.map((tag) => tag.name).join(", ")}
                                        variant="outlined"
                                        size="small"
                                        sx={{ px: 1 }}
                                    ></Chip>
                                </Box>
                            </Box>
                            <Box flexShrink={1}>
                                <IconButton onClick={() => setTagsModalOpen(true)}>
                                    <Edit></Edit>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Card>
                {/* Notes */}
                <Card>
                    <Card>
                        <Box display={"flex"} flexDirection={"column"} paddingX={2} paddingY={2}>
                            <Avatar
                                sx={{
                                    bgcolor: "transparent",
                                    color: (theme) => theme.palette.text.disabled,
                                }}
                            >
                                <SpeakerNotesOutlined fontSize="large"></SpeakerNotesOutlined>
                            </Avatar>
                            <Box display={"flex"}>
                                <Box flexGrow={1}>
                                    <Typography
                                        variant="overline"
                                        color={theme.palette.text.secondary}
                                    >
                                        Notes
                                    </Typography>
                                    <Typography variant="body2">
                                        {item.notes.length > 200
                                            ? item.notes.substring(0, 120) + "..."
                                            : item.notes}
                                    </Typography>
                                </Box>
                                <Box flexShrink={1}>
                                    <IconButton onClick={() => setNotesModalOpen(true)}>
                                        <Edit></Edit>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Card>
            </Box>
            {/* Modals */}
            <EditTitle
                titleObject={{ id: item, title: item.title, titleFor: item.name }}
                open={titleModalOpen}
                onClose={() => setTitleModalOpen(false)}
            ></EditTitle>
            <InfoModal
                data={infoModalData}
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
            ></InfoModal>
            <EditTags
                tagsObject={{ id: item, tags: item.tags.map((tag) => tag.id) }}
                open={tagsModalOpen}
                onClose={() => setTagsModalOpen(false)}
            ></EditTags>
            <EditNotes
                notesObject={{ id: item, notes: item.notes }}
                open={notesModalOpen}
                onClose={() => setNotesModalOpen(false)}
            ></EditNotes>
            {/* ../Modals */}
        </div>
    );
};

export default VideoLibraryItemDetails;
