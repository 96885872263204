import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import ModalOutline from ".";
import { Close, InfoOutlined } from "@mui/icons-material";

const EditNotes = (props: { notesObject: any; open: boolean; onClose: any }) => {
    const { notes = "", id = 0 } = props.notesObject;
    const [innerNotes, setInnerNotes] = React.useState(notes);

    React.useEffect(() => {
        setTimeout(() => {
            setInnerNotes(notes);
        }, 100);
    }, [notes]);

    // Methods
    const submitModal = () => {
        // Must return `true` to hide the modal
        if (id) {
            // Update
        }
        return true;
    };
    return (
        <ModalOutline
            open={props.open}
            title="Notes"
            cancelable
            submitText="Save"
            width="550"
            dense
            onSubmit={() => submitModal()}
            onClose={props.onClose}
            onCancel={() => setInnerNotes(notes)}
        >
            <TextField
                autoFocus
                value={innerNotes}
                onChange={(e) => setInnerNotes(e.target.value)}
                placeholder="Write your notes..."
                helperText={
                    <>
                        <Box display={"flex"} mt={1} columnGap={1} flexWrap={"wrap"}>
                            <Box>
                                <InfoOutlined fontSize="small"></InfoOutlined>
                            </Box>
                            <Box>
                                Write or dictate info about the surgery. <br /> No patient data.
                            </Box>
                        </Box>
                    </>
                }
                variant="outlined"
                fullWidth
                multiline
                minRows={3}
            ></TextField>
        </ModalOutline>
    );
};

export default EditNotes;
