// import React, { PropsWithChildren, ReactNode, useEffect } from "react";
// import { Route, Redirect, useLocation, RouteProps, useHistory } from "react-router-dom";
// import { DeepReadonly } from "utility-types";
//
// import { useCmrAuth, User } from "state/CMRAuth";
// import Auth, { CognitoUser } from "@aws-amplify/auth";
//
// // export interface Props extends Omit<RouteProps, "render"> {
// //     groups?: string[];
// //     component?: React.ComponentType;
// //     path: string; // Make path required
// // }
//
// // TODO add wildcard matching, link to CMR auth class, extract to common functionality
// const isUserInOneOrMoreGroups = (user: DeepReadonly<User>, groups: string[]) =>
//     groups.some((group) => user.groups.includes(group));
//
// /**
//  * Controls the render of a React Router Route depending on the current state of app auth.
//  * + If no user is logged in, it redirects to /login
//  * + If the app is still loading the logged in (or not) user, it renders nothing
//  * + If a user is logged in, it renders the provided component
//  *
//  * If an array of groups is provided, and the logged-in user does not belong to any of the groups,
//  * it redirects to /.
//  *
//  * @example
//  * <CMRAuthRoute path="/123" exact component={SomeComponentNeedingLogin}>
//  * <CMRAuthRoute path="/123" exact component={SomeSecureComponent} groups={["function.secure", "function.clt"]} />
//  */
// const CMRAuthRoute: React.FC = (ComposedComponent: ReactNode) => {
//     const router = useHistory();
//     const { isLoading, user } = useCmrAuth();
//
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     // @ts-ignore
//     useEffect(async () => {
//         const current = await Auth.currentSession();
//         const token = current.getAccessToken().getJwtToken();
//         console.log(token, "token");
//         if (!token) router.push("/login");
//     }, []);
//
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     // @ts-ignore
//     return <ComposedComponent />;
//
//     // return Component ? <Component /> : <>{children}</>;
//
//     // return (
//     //     <Route
//     //         // eslint-disable-next-line react/jsx-props-no-spreading
//     //         {...routeProps}
//     //         render={() => {
//     //             // Don't yet know if we're authed
//     //             if (isLoading) return null;
//     //
//     //             // No user - need to log in
//     //             if (!user?.username)
//     //                 return (
//     //                     <Redirect
//     //                         to={{
//     //                             pathname: "/login",
//     //                             state: { from: location },
//     //                         }}
//     //                     />
//     //                 );
//     //
//     //             // User is not in one of the required groups - go back to the home page
//     //             // TODO unauthorized UX
//     //             if (groups && !isUserInOneOrMoreGroups(user, groups))
//     //                 return (
//     //                     <Redirect
//     //                         to={{
//     //                             pathname: "/",
//     //                             state: { from: location },
//     //                         }}
//     //                     />
//     //                 );
//     //             return Component ? <Component /> : <>{children}</>;
//     //         }}
//     //     />
//     // );
// };
//
// export default CMRAuthRoute;

import React, { useEffect } from "react";
import Auth from "@aws-amplify/auth";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

const CMRAuthRoute = (ComposedComponent: any) => {
    const router = useHistory();
    const location = useLocation();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(async () => {
        console.log(location, "location");

        try {
            const current = await Auth.currentSession();
            const token = current.getAccessToken().getJwtToken();
            if (!token) router.push("/login");

            if (token && location.pathname === "/login") router.push("/logbook");
        } catch (e) {
            console.log(e);
            if (e === "No current user") router.push("/login");
        }
    }, []);

    return <ComposedComponent />;
};

export default CMRAuthRoute;
