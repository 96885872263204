import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";
import { StatesRoleI, StateSurgeriesIdesI } from "./types";

const name = "statistics";

export const initialState = {
    isLoading: false,
    error: "",
    statistics: [],
    statisticDetails: [],
    surgeries: [],
};

export const fetchStats = createAsyncThunk("stats/fetch", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/stats/summary`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const fetchStatsDetails = createAsyncThunk(
    "stats/details/fetch",
    async (data: StatesRoleI, thunkApi) => {
        try {
            const config = await tokenHeader(data);
            const resp = await axios.get(`${baseUrl}/stats/details`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const fetchSurgeriesWithIds = createAsyncThunk(
    "stats/surgeries/fetch",
    async (data: StateSurgeriesIdesI, thunkApi) => {
        try {
            const config = await tokenHeader(data);
            const resp = await axios.get(`${baseUrl}/stats/surgeries`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const isAnyOf = (...matchers: Array<string | { type: string }>) => (action: AnyAction) =>
    matchers.some((matcher) =>
        typeof matcher === "string" ? matcher === action.type : matcher.type === action.type
    );

const statisticsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Fetch states
        builder.addCase(fetchStats.fulfilled, (state, action) => {
            state.isLoading = false;
            state.statistics = action.payload;
        });

        //Fetch state details by role
        builder.addCase(fetchStatsDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.statisticDetails = action.payload;
        });

        //Fetch surgeries with ids
        builder.addCase(fetchSurgeriesWithIds.fulfilled, (state, action) => {
            state.isLoading = false;
            state.surgeries = action.payload;
        });

        builder.addMatcher(
            isAnyOf(fetchStatsDetails.pending, fetchStats.pending, fetchSurgeriesWithIds.pending),
            (state) => {
                state.isLoading = true;
            }
        );

        builder.addMatcher(
            isAnyOf(
                fetchStatsDetails.rejected,
                fetchStats.rejected,
                fetchSurgeriesWithIds.rejected
            ),
            (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            }
        );
    },
});

export default statisticsSlice;
