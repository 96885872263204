import React from "react";
import { usePageTitle } from "hooks";
import { Box, useTheme } from "@mui/system";
import {
    Avatar,
    ButtonBase,
    Card,
    CardActionArea,
    Chip,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Paper,
    Typography,
} from "@mui/material";
import {
    ChevronRight,
    LocalOfferOutlined,
    School,
    Tag,
    TagOutlined,
    Timer,
    TimerOutlined,
} from "@mui/icons-material";
import useIsBreakpoint from "hooks/useIsBreakpoint";
import { Link } from "react-router-dom";
import { zoomableCardPaper } from "ui/resources/mui-prestyles";
import getTheme from "mixins/GetTheme";
import { useTypedSelector } from "hooks/store";
import { TagType } from "./../../components/Modals/EditTags";

export interface ListItemType {
    id: number;
    date: string;
    time: string;
    type?: string;
    elapsedTime?: string;
    title: string;
    name: string;
    thumbnail: string;
    tags: TagType[];
}
export interface ListItemParentType {
    date: string;
    items: ListItemType[];
}

const LogbookPage: React.FC = () => {
    usePageTitle("Logbook");
    const searchQuery = useTypedSelector((state) => state.appSettings.searchQuery);
    const theme = useTheme();
    const customTheme = getTheme(theme.palette.mode).custom;

    const [list, setList] = React.useState<ListItemParentType[]>([]);
    const listFromApi = [
        // TODO Ask about the correct keys:value-types of the list
        {
            date: "August 2020",
            items: [
                {
                    id: 1,
                    date: "12 Aug",
                    time: "01:20 PM",
                    type: "Research",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                    tags: [
                        {
                            name: "Heart",
                            id: 1,
                        },
                    ],
                },
                {
                    id: 2,
                    date: "18 Aug",
                    time: "05:20 PM",
                    type: "Example",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                    tags: [],
                },
                {
                    id: 3,
                    date: "25 Aug",
                    time: "12:20 AM",
                    type: "Surgery",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                    tags: [
                        {
                            name: "Ear",
                            id: 2,
                        },
                        {
                            name: "Mouth",
                            id: 3,
                        },
                        {
                            name: "Hand",
                            id: 4,
                        },
                    ],
                },
                {
                    id: 1,
                    date: "12 Aug",
                    time: "01:20 PM",
                    type: "Research",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                    tags: [
                        {
                            name: "Heart",
                            id: 1,
                        },
                    ],
                },
                {
                    id: 2,
                    date: "18 Aug",
                    time: "05:20 PM",
                    type: "Example",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                    tags: [],
                },
                {
                    id: 3,
                    date: "25 Aug",
                    time: "12:20 AM",
                    type: "Surgery",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                    tags: [
                        {
                            name: "Ear",
                            id: 2,
                        },
                        {
                            name: "Mouth",
                            id: 3,
                        },
                        {
                            name: "Hand",
                            id: 4,
                        },
                    ],
                },
            ],
        },
        {
            date: "September 2020",
            items: [
                {
                    id: 4,
                    date: "12 Aug",
                    time: "01:20 PM",
                    type: "Research",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                    tags: [
                        {
                            name: "Heart",
                            id: 1,
                        },
                    ],
                },
                {
                    id: 5,
                    date: "18 Aug",
                    time: "05:20 PM",
                    type: "Example",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                    tags: [],
                },
                {
                    id: 6,
                    date: "25 Aug",
                    time: "12:20 AM",
                    type: "Surgery",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                    tags: [
                        {
                            name: "Ear",
                            id: 2,
                        },
                        {
                            name: "Mouth",
                            id: 3,
                        },
                        {
                            name: "Hand",
                            id: 4,
                        },
                    ],
                },
            ],
        },
        {
            date: "November 2020",
            items: [
                {
                    id: 4,
                    date: "12 Aug",
                    time: "01:20 PM",
                    type: "Research",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                    tags: [
                        {
                            name: "Heart",
                            id: 1,
                        },
                    ],
                },
                {
                    id: 5,
                    date: "18 Aug",
                    time: "05:20 PM",
                    type: "Example",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                    tags: [],
                },
                {
                    id: 6,
                    date: "25 Aug",
                    time: "12:20 AM",
                    type: "Surgery",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                    tags: [
                        {
                            name: "Ear",
                            id: 2,
                        },
                        {
                            name: "Mouth",
                            id: 3,
                        },
                        {
                            name: "Hand",
                            id: 4,
                        },
                    ],
                },
            ],
        },
        {
            date: "December 2020",
            items: [
                {
                    id: 4,
                    date: "12 Aug",
                    time: "01:20 PM",
                    type: "Research",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                    tags: [
                        {
                            name: "Heart",
                            id: 1,
                        },
                    ],
                },
                {
                    id: 5,
                    date: "18 Aug",
                    time: "05:20 PM",
                    type: "Example",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                    tags: [],
                },
                {
                    id: 6,
                    date: "25 Aug",
                    time: "12:20 AM",
                    type: "Surgery",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                    tags: [
                        {
                            name: "Ear",
                            id: 2,
                        },
                        {
                            name: "Mouth",
                            id: 3,
                        },
                        {
                            name: "Hand",
                            id: 4,
                        },
                    ],
                },
            ],
        },
    ];

    // Search query watcher
    React.useEffect(() => {
        const newList = listFromApi
            .map((parentItem) => {
                return {
                    date: parentItem.date,
                    items: parentItem.items.filter(
                        (item) =>
                            !searchQuery.trim() ||
                            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            item.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            item.name.toLowerCase().includes(searchQuery.toLowerCase())
                    ),
                };
            })
            .filter((parentItem) => parentItem.items.length > 0);
        setList(newList);
    }, [searchQuery]);

    const isMdAndUp = useIsBreakpoint("md", ">");
    const imageWidth = isMdAndUp ? 150 : 90;
    return (
        <div>
            {list.map((period, i) => (
                <List key={i}>
                    <ListSubheader
                        sx={{
                            backgroundColor: theme.palette.background.default,
                            mb: 1,
                        }}
                    >
                        {period.date}
                    </ListSubheader>
                    <Grid container alignItems={"stretch"} spacing={3}>
                        {period.items.map((item, ii) => (
                            <Grid key={ii} item xs={12} lg={6}>
                                <Card
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        mb: 1.5,
                                    }}
                                    component={Link}
                                    to={`/video-library/${item.id}/details/show-video-card`}
                                >
                                    <Paper sx={{ height: "100%", ...zoomableCardPaper() }}>
                                        <CardActionArea sx={{ height: "100%" }}>
                                            <Grid container sx={{ height: "100%" }}>
                                                <Box
                                                    sx={{ backgroundColor: "transparent" }}
                                                    flexShrink={1}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            width: imageWidth,
                                                            height: "100%",
                                                        }}
                                                        variant="rounded"
                                                        src={item.thumbnail}
                                                    ></Avatar>
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    flexGrow={1}
                                                    maxWidth={`calc(100% - ${imageWidth}px)`}
                                                    sx={{
                                                        px: 2,
                                                        py: 2,
                                                        backgroundColor: "transparent",
                                                    }}
                                                >
                                                    <Grid container>
                                                        <Box
                                                            display={"flex"}
                                                            rowGap={3}
                                                            flexDirection={"column"}
                                                            flexGrow={1}
                                                            sx={{
                                                                backgroundColor: "transparent",
                                                            }}
                                                            justifyContent={"space-between"}
                                                        >
                                                            <Box>
                                                                <Box
                                                                    display={"flex"}
                                                                    alignItems={"flex-end"}
                                                                >
                                                                    <Box flexGrow={1}>
                                                                        <Typography
                                                                            variant="caption"
                                                                            color={
                                                                                customTheme.secondaryText
                                                                            }
                                                                        >
                                                                            {item.type}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box flexShrink={1}>
                                                                        <Box
                                                                            display={"flex"}
                                                                            columnGap={1}
                                                                            alignItems={"center"}
                                                                        >
                                                                            <School
                                                                                fontSize="small"
                                                                                sx={{
                                                                                    color:
                                                                                        customTheme.secondaryText,
                                                                                }}
                                                                            ></School>
                                                                            <Typography
                                                                                variant="caption"
                                                                                color={
                                                                                    customTheme.secondaryText
                                                                                }
                                                                            >
                                                                                {item.name}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Typography variant="body1">
                                                                    {item.title}
                                                                </Typography>
                                                            </Box>
                                                            <Box
                                                                display={"flex"}
                                                                flexDirection={"column"}
                                                                rowGap={1}
                                                            >
                                                                {item.tags.length > 0 && (
                                                                    <Box
                                                                        display={"flex"}
                                                                        columnGap={1}
                                                                    >
                                                                        <Chip
                                                                            icon={
                                                                                <LocalOfferOutlined />
                                                                            }
                                                                            label={item.tags
                                                                                .map(
                                                                                    (tag, ti) =>
                                                                                        tag.name
                                                                                )
                                                                                .join(", ")}
                                                                            size="small"
                                                                            sx={{
                                                                                bgcolor:
                                                                                    "transparent",
                                                                                columnGap: 1,
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                )}
                                                                <Box display={"flex"} columnGap={1}>
                                                                    <Chip
                                                                        icon={<TimerOutlined />}
                                                                        label={`${item.date} : ${item.time}`}
                                                                        size="small"
                                                                        sx={{
                                                                            bgcolor: "transparent",
                                                                            columnGap: 1,
                                                                        }}
                                                                    ></Chip>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </CardActionArea>
                                    </Paper>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </List>
            ))}
        </div>
    );
};

export default LogbookPage;
