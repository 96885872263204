import React from "react";
import { useParams } from "react-router-dom";

import { usePageTitle } from "hooks";
import { useSurgeries } from "hooks/surgeries";
import LoadingIndicator from "ui/components/LoadingIndicator";

const SurgeryDetailPage: React.FC = () => {
    const { surgeryKey } = useParams<{ surgeryKey: string }>();
    usePageTitle(`Surgery ${surgeryKey}`);

    // TODO this is non-ideal, but will do for now
    const [surgeries, { isLoading }] = useSurgeries();
    const surgery = surgeries.find((s) => s.surgeryKey === surgeryKey);

    return (
        <>
            <h3>Surgery {surgeryKey}</h3>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <p>
                    {surgery
                        ? `start: ${surgery.startTime}`
                        : `No surgery matches surgery key ${surgeryKey}`}
                </p>
            )}
        </>
    );
};

export default SurgeryDetailPage;
