import useIsBreakpoint from "hooks/useIsBreakpoint";
import React from "react";
import AppHeader from "./AppHeader";
import Drawer from "./Drawer";

const AppNavbar = (props?: any) => {
    const isMdAndUp = useIsBreakpoint("md", ">");
    return (
        <>
            <AppHeader /> {isMdAndUp && <Drawer links={props.links} />}
        </>
    );
};

export default AppNavbar;
