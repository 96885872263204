import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import ModalOutline from ".";
import { Close } from "@mui/icons-material";

const EditTitle = (props: { titleObject: any; open: boolean; onClose: any }) => {
    const { title = "", titleFor = "", id = 0 } = props.titleObject;
    const [innerTitle, setInnerTitle] = React.useState(title);

    React.useEffect(() => {
        setTimeout(() => {
            setInnerTitle(title);
        }, 100);
    }, [title]);

    // Methods
    const submitModal = () => {
        // Must return `true` to hide the modal
        if (id) {
            // Update
        }
        return true;
    };
    return (
        <ModalOutline
            open={props.open}
            title="Title"
            cancelable
            submitText="Save"
            width="550"
            onSubmit={() => submitModal()}
            onClose={props.onClose}
            onCancel={() => setInnerTitle(title)}
        >
            <TextField
                autoFocus
                value={innerTitle}
                onChange={(e) => setInnerTitle(e.target.value)}
                label="Title"
                helperText={`Add title${titleFor && ` for ${titleFor}`}`}
                variant="outlined"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setInnerTitle("")}>
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            ></TextField>
        </ModalOutline>
    );
};

export default EditTitle;
