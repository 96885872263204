import {
    Box,
    ButtonBase,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import { usePageTitle } from "hooks";
import { fixDurations } from "mixins/Utils";
import React from "react";
import { Link, useParams } from "react-router-dom";
import sampleVideo from "ui/resources/videos/big_buck_bunny_720p_1mb.mp4";
import VREPlayer from "videojs-react-enhanced";
import "video.js/dist/video-js.css";
import VideojsCustomPlugin from "ui/components/Utils/VideojsCustomPlugin";
import {
    Add,
    Bookmark,
    BookmarkBorder,
    BookmarkOutlined,
    ChevronRight,
    Edit,
    EditOutlined,
    School,
} from "@mui/icons-material";
import getTheme from "mixins/GetTheme";
import EditBookmark from "ui/components/Modals/EditBookmark";

const VideoLibraryItem = () => {
    const { id } = useParams<{ id: string }>();
    const item = {
        // TODO Ask about the correct keys:value-types of the object
        // This object will be fetched from API
        id,
        name: "DRY LAB",
        date: "Aug 18, 2020",
        time: "01:20:57 PM",
        duration: "3420", // Seconds
        hospital: "EBP - Pod (Upstairs)",
        type: "Research",
        title: "Intern Experience",
        video: {
            thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
            elapsedTime: "522", // Seconds
            src: sampleVideo,
        },
        participants: [
            {
                id: 1,
                name: "Test UserPR",
                avatar: "https://lwlies.com/wp-content/uploads/2017/04/avatar-2009.jpg",
            },
            {
                id: 2,
                name: "David Hull",
                avatar: "",
            },
        ],
        tags: [
            {
                id: 1,
                name: "Test",
            },
            {
                id: 2,
                name: "New Tag",
            },
        ],
        notes:
            "Magna minim fugiat officia ea deserunt consectetur magna reprehenderit amet. Elit sunt ea exercitation velit occaecat velit dolor officia do laboris enim veniam. Exercitation excepteur pariatur esse ex ipsum tempor adipisicing et duis tempor culpa. Pariatur deserunt ut eu esse incididunt excepteur cillum fugiat ullamco ut aute deserunt. Irure aliqua labore irure esse occaecat fugiat. Fugiat mollit sit consequat consequat consequat.",
        bookmarks: [
            {
                id: 1,
                time: "156", // seconds
                title: "Grasped the green think",
            },
            {
                id: 2,
                time: "224",
                title: "Did the right job",
            },
            {
                id: 3,
                time: "654",
                title: "Almost finished the simulation",
            },
            {
                id: 4,
                time: "156", // seconds
                title: "Grasped the green think",
            },
            {
                id: 5,
                time: "224",
                title: "Did the right job",
            },
            {
                id: 6,
                time: "654",
                title: "Almost finished the simulation",
            },
        ],
    };
    item.duration = fixDurations(item.duration);
    item.video.elapsedTime = fixDurations(item.video.elapsedTime);
    usePageTitle(item.name);
    const theme = useTheme();
    const customTheme = getTheme(theme.palette.mode).custom;
    const [canPlayNext, setCanPlayNext] = React.useState(false);
    const [bookmarkModalOpen, setBookmarkModalOpen] = React.useState(false);
    const [bookmarkModalProps, setBookmarkModalProps] = React.useState({});
    const [videoEl, setVideoEl] = React.useState({
        currentTime: 0,
        pause: () => {
            return true;
        },
    });
    const pauseVideo = () => {
        videoEl.pause && videoEl.pause();
    };
    const updateBookmark = (bookmark: any) => {
        setBookmarkModalProps({ ...bookmark });
        setBookmarkModalOpen(true);
        pauseVideo();
    };
    const addBookmark = () => {
        const time = videoEl.currentTime;
        setBookmarkModalProps({ time });
        setBookmarkModalOpen(true);
        pauseVideo();
    };
    const videoPlayed = (e: any) => {
        if (e?.target?.player?.children_) {
            const el = e.target.player.children_[0];
            setVideoEl(el);
        }
    };
    return (
        <div>
            <Grid container flexDirection={"column"} mx={"auto"} mb={6} maxWidth={"sm"}>
                <Box>
                    <Card>
                        <CardMedia
                            component={VREPlayer}
                            playerOptions={{
                                src: item.video.src,
                                controls: true,
                                preload: "metadata",
                                loop: false,
                                muted: false,
                                playsinline: true,
                            }}
                            videojsOptions={{
                                fluid: true,
                                plugins: [
                                    {
                                        name: "VideojsCustomPlugin",
                                        plugin: (player: any) => {
                                            VideojsCustomPlugin(player, canPlayNext);
                                        },
                                        options: {},
                                    },
                                ],
                            }}
                            resources={{
                                poster: item.video.thumbnail,
                            }}
                            onPlay={videoPlayed}
                        ></CardMedia>
                        <Card component={Link} to={`/video-library/${item.id}/details`}>
                            <CardActionArea>
                                <CardContent>
                                    <Grid container flexDirection={"column"}>
                                        <Grid container>
                                            <Box flexGrow={1}>
                                                <Typography variant="caption">
                                                    {item.date} {item.time}
                                                </Typography>
                                            </Box>
                                            <Box
                                                flexShrink={0}
                                                display={"flex"}
                                                alignItems={"end"}
                                                columnGap={1}
                                            >
                                                <School
                                                    sx={{ color: customTheme.secondaryText }}
                                                ></School>
                                                <Typography
                                                    color={customTheme.secondaryText}
                                                    variant="caption"
                                                    fontWeight={500}
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid>
                                            <ListItem disablePadding>
                                                <ListItemText
                                                    primary={item.title}
                                                    secondary={item.type}
                                                    secondaryTypographyProps={{
                                                        sx: { color: customTheme.secondaryText },
                                                    }}
                                                    sx={{
                                                        flexDirection: "column-reverse",
                                                        display: "flex",
                                                    }}
                                                ></ListItemText>
                                                <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                                                    <ChevronRight color="primary"></ChevronRight>
                                                </ListItemIcon>
                                            </ListItem>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <CardContent
                            sx={{
                                px: 0,
                                pt: 0,
                                pb: "8px !important",
                                bgcolor: theme.palette.background.default,
                            }}
                        >
                            <List>
                                <ListSubheader
                                    sx={{
                                        bgcolor: "transparent",
                                        justifyContent: "space-between",
                                        display: "flex",
                                    }}
                                >
                                    Bookmarks
                                    <IconButton
                                        aria-label="add new bookmark"
                                        onClick={() => addBookmark()}
                                    >
                                        <Add></Add>
                                    </IconButton>
                                </ListSubheader>
                                {item.bookmarks.map((bookmark, i) => (
                                    <Card key={i} sx={{ mt: i > 0 ? 2 : 0, mx: 2 }}>
                                        <CardActionArea
                                            aria-label="update bookmark"
                                            onClick={() => updateBookmark(bookmark)}
                                        >
                                            <ListItem>
                                                <ListItemIcon>
                                                    <BookmarkBorder color="primary"></BookmarkBorder>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={bookmark.title}
                                                    secondary={fixDurations(bookmark.time)}
                                                    sx={{
                                                        flexDirection: "column-reverse",
                                                        display: "flex",
                                                    }}
                                                ></ListItemText>
                                                <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                                                    <EditOutlined color="primary"></EditOutlined>
                                                </ListItemIcon>
                                            </ListItem>
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
            {/* Modals */}
            <EditBookmark
                bookmark={bookmarkModalProps}
                open={bookmarkModalOpen}
                onClose={() => setBookmarkModalOpen(false)}
            ></EditBookmark>
            {/* ../Modals */}
        </div>
    );
};

export default VideoLibraryItem;
