import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { vtdsURL, tokenHeader } from "../../config";
import { FetchSessionsI, CreateSessionI, CreateSessionV2I } from "./types";

const name = "trainer";

export const initialState = {
    isLoading: false,
    error: "",
    parameters: [],
    tasks: [],
    myTasks: [],
    sessions: [],
};

export const fetchTrainerParameters = createAsyncThunk(
    "trainer/parameters",
    async (_, thunkApi) => {
        try {
            const config = await tokenHeader();
            const resp = await axios.get(`${vtdsURL}/parameters`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const fetchAllTasks = createAsyncThunk("trainer/fetchAllTasks", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${vtdsURL}/tasks`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const fetchMyTasks = createAsyncThunk("trainer/fetchMyTasks", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${vtdsURL}/users/me`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const fetchSessions = createAsyncThunk(
    "trainer/fetchSessions",
    async (data: FetchSessionsI, thunkApi) => {
        try {
            const config = await tokenHeader(data);
            const resp = await axios.get(`${vtdsURL}/completed-tasks`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createSessionV1 = createAsyncThunk(
    "trainer/createSessionV1",
    async (data: CreateSessionI, thunkApi) => {
        try {
            const config = await tokenHeader();
            const resp = await axios.post(`${vtdsURL}/completed-tasks/claim-v1`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const createSessionV2 = createAsyncThunk(
    "trainer/createSessionV2",
    async (data: CreateSessionV2I, thunkApi) => {
        try {
            const config = await tokenHeader();
            const resp = await axios.post(`${vtdsURL}/login-periods`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const isAnyOf = (...matchers: Array<string | { type: string }>) => (action: AnyAction) =>
    matchers.some((matcher) =>
        typeof matcher === "string" ? matcher === action.type : matcher.type === action.type
    );

const photosSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Fetch trainer parameters
        builder.addCase(fetchTrainerParameters.fulfilled, (state, action) => {
            state.isLoading = false;
            state.parameters = action.payload;
        });

        //Fetch all tasks
        builder.addCase(fetchAllTasks.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tasks = action.payload;
        });

        //Fetch my tasks
        builder.addCase(fetchMyTasks.fulfilled, (state, action) => {
            state.isLoading = false;
            state.myTasks = action.payload;
        });

        //Fetch sessions
        builder.addCase(fetchSessions.fulfilled, (state, action) => {
            state.isLoading = false;
            state.sessions = action.payload;
        });

        //Create session v1
        builder.addCase(createSessionV1.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        //Create session v2
        builder.addCase(createSessionV2.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        builder.addMatcher(
            isAnyOf(
                fetchTrainerParameters.pending,
                fetchAllTasks.pending,
                fetchMyTasks.pending,
                fetchSessions.pending,
                createSessionV1.pending,
                createSessionV2.pending
            ),
            (state) => {
                state.isLoading = true;
            }
        );

        builder.addMatcher(
            isAnyOf(
                fetchTrainerParameters.rejected,
                fetchAllTasks.rejected,
                fetchMyTasks.rejected,
                fetchSessions.rejected,
                createSessionV1.rejected,
                createSessionV2.rejected
            ),
            (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            }
        );
    },
});

export default photosSlice;
