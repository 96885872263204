import { CognitoConfig } from "state/CMRAuth";
import Auth from "@aws-amplify/auth";

const SUPPORTED_STAGES = ["dev", "prod", "stage"] as const;
type Stage = typeof SUPPORTED_STAGES[number];
type MultiStageConfig<T> = Readonly<Record<Stage, T>>;

const isValidStage = (maybeStage: unknown): maybeStage is Stage =>
    typeof maybeStage === "string" && (SUPPORTED_STAGES as readonly string[]).includes(maybeStage);

// ************************************************************

const COGNITO_ALL_STAGES: MultiStageConfig<CognitoConfig> = {
    dev: {
        appClientId: "q9ch0584g73a6heu3emjlgqh5", // Bootstrapping: update me
        domain: "eu.authdev.cmrsurgical.com",
        region: "eu-west-2",
        userPoolId: "eu-west-2_FP6H5TPZe",
    },
    prod: {
        appClientId: "1nl7jls3uqpkes32kol5r0anig", // Bootstrapping: update me
        domain: "eu.auth.cmrsurgical.com",
        region: "eu-west-2",
        userPoolId: "eu-west-2_mQiFbuxB0",
    },
    stage: {
        appClientId: "1nl7jls3uqpkes32kol5r0anig", // Bootstrapping: update me
        domain: "eu.auth.cmrsurgical.com",
        region: "eu-west-2",
        userPoolId: "eu-west-2_mQiFbuxB0",
    },
};

// ************************************************************

type LoggingConfig = {
    sumoLogicEndpoint: string;
};

const LOGGING_ALL_STAGES: MultiStageConfig<LoggingConfig> = {
    dev: {
        sumoLogicEndpoint:
            // Bootstrapping: update me
            "https://endpoint1.collection.eu.sumologic.com/receiver/v1/http/ZaVnC4dhaV2n1u2hCuUDalrw44wvVGTeFzJNeH186QQxleM3Hpr7IpV_mVNsohniYK5d7Ihw5mx7nv0Wgc-G8hQ0_mv9Qx32ZJjSBjpKGVI031P_VaZDDA==",
    },
    prod: {
        sumoLogicEndpoint:
            // Bootstrapping: update me
            "https://endpoint1.collection.eu.sumologic.com/receiver/v1/http/ZaVnC4dhaV3rev9r7gJABMMKHTqejC1Ae41F71wLTcUNHMzvs_qHgpYWjWHRKnXAgSum_lKmDnU9GrXWHpeEbty_wZ0dLE1M1fZYI1QLo9ujWRBUzejjgA==",
    },
    stage: {
        sumoLogicEndpoint:
            // Bootstrapping: update me
            "https://endpoint1.collection.eu.sumologic.com/receiver/v1/http/ZaVnC4dhaV3rev9r7gJABMMKHTqejC1Ae41F71wLTcUNHMzvs_qHgpYWjWHRKnXAgSum_lKmDnU9GrXWHpeEbty_wZ0dLE1M1fZYI1QLo9ujWRBUzejjgA==",
    },
};

// ************************************************************

type SurgeryServiceConfig = {
    baseUrl: string;
};

const SURGERY_SERVICE_ALL_STAGES: MultiStageConfig<SurgeryServiceConfig> = {
    dev: {
        baseUrl: "https://surgeries.api.aldev.cmrsurgical.com",
    },
    prod: {
        baseUrl: "https://surgeries.api.cmrsurgical.com",
    },
    stage: {
        baseUrl: "https://staging.myversius.cmrsurgical.com/api/",
    },
};

// ************************************************************

if (process.env.REACT_APP_STAGE && !isValidStage(process.env.REACT_APP_STAGE))
    throw new Error(
        `Invalid value for environment variable REACT_APP_STAGE: ${process.env.REACT_APP_STAGE} - must be one of ${SUPPORTED_STAGES}`
    );

export const STAGE: Stage = (process.env.REACT_APP_STAGE as Stage) || "prod";

export const COGNITO = COGNITO_ALL_STAGES[STAGE];
export const LOGGING = LOGGING_ALL_STAGES[STAGE];
export const SURGERY_SERVICE = SURGERY_SERVICE_ALL_STAGES[STAGE];

export const tokenHeader = async (params: any = null, type = false) => {
    const current = await Auth.currentSession();
    const token = current.getAccessToken().getJwtToken();
    let obj = {} as any;
    obj.headers = {
        Authorization: `Bearer ${token}`,
    };

    if (params) {
        obj.params = params;
    }

    if (type) {
        const formData = {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
        };
        obj = { ...obj, ...formData };
    }

    return obj;
};

const version = "v5";
export const baseUrl = `https://staging.myversius.cmrsurgical.com/api/${version}`;
export const notificationsUrl = "https://notification.api.aldev.cmrsurgical.com";
export const vtdsURL = "https://versiustrainer-airsource.api.aldev.cmrsurgical.com/v1";

// Also default export a combined config for convenience
export default {
    COGNITO,
    LOGGING,
};
