import React from "react";
import { NavLink } from "react-router-dom";

import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import { ThemeProvider } from "@mui/material/styles";
import darkTheme from "./../../../mixins/DarkTheme";

import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Avatar, SvgIconTypeMap, useTheme } from "@mui/material";
import getTheme from "mixins/GetTheme";
import CustomSvgIcon from "../Utils/CustomSvgIcon";

const AppNavbar = (props: any) => {
    const theme = useTheme();
    const customThemeDark = getTheme("dark").custom;
    const customTheme = getTheme(theme.palette.mode).custom;
    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <Box
                    position={"fixed"}
                    bottom={0}
                    left={0}
                    width={"100%"}
                    sx={{
                        mt: "auto",
                        backgroundColor: (theme) => theme.palette.secondary.main,
                    }}
                    zIndex={1}
                >
                    <BottomNavigation
                        showLabels
                        sx={{
                            maxWidth: 500,
                            height: 76,
                            mx: "auto",
                            color: "white",
                            backgroundColor: "transparent",
                        }}
                    >
                        {props?.links?.map &&
                            props?.links?.map(
                                (
                                    link: {
                                        label: string;
                                        to: string;
                                        icon: string;
                                    },
                                    i: number
                                ) => (
                                    <BottomNavigationAction
                                        key={i}
                                        component={NavLink}
                                        to={link.to}
                                        sx={{
                                            pt: 1,
                                            fontWeight: 400,
                                            rowGap: 1.5,
                                            minWidth: 70,
                                            color: customThemeDark.textOnSecondaryColor,
                                            "&.active *": {
                                                color: customTheme.primaryText,
                                            },
                                        }}
                                        label={link.label}
                                        icon={
                                            <CustomSvgIcon
                                                size="1.25"
                                                scale="2"
                                                src={link.icon}
                                            ></CustomSvgIcon>
                                        }
                                    />
                                )
                            )}
                    </BottomNavigation>
                </Box>
            </ThemeProvider>
        </>
    );
};

export default AppNavbar;
