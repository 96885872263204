import { Avatar, AvatarTypeMap, SvgIcon } from "@mui/material";
import SVG, { Props as SVGProps } from "react-inlinesvg";
import React from "react";

const CustomSvgIcon = (props: any) => {
    return (
        <SVG
            className="custom-svg-icon"
            src={props.src}
            style={{
                transform: `scale(${props.scale || "1"})`,
                fontSize: `${props.size || "1"}rem`,
                color: `${props.color || "inherit"}`,
            }}
        ></SVG>
    );
};

export default CustomSvgIcon;
