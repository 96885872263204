import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import { ActionType } from "typesafe-actions";
import { COGNITO } from "config";
import { createLoggerMiddleware } from "logger";
import CMRAuth from "state/CMRAuth";
import surgeriesState from "state/surgeries";
import appSettingsState from "state/appSettings";
import userDataState from "state/userData";
import settingsState from "state/settings";
import videosState from "state/videos";
import bookmarksState from "state/bookmarks";
import claimState from "state/claims";
import proceduresState from "state/procedures";
import statisticsState from "state/statistics";
import surgeryState from "state/surgery";
import tagsState from "state/tags";
import notificationsState from "state/notifications";
import thumbnailsState from "state/thumbnails";
import photosState from "state/photos";
import trainerState from "state/trainer";

const rootReducer = combineReducers({
    CMRAuth: CMRAuth.getReducer(),
    surgeries: surgeriesState.reducer,
    appSettings: appSettingsState.reducer,
    userData: userDataState.reducer,
    termsData: settingsState.reducer,
    videosData: videosState.reducer,
    bookmarksData: bookmarksState.reducer,
    claimsData: claimState.reducer,
    proceduresData: proceduresState.reducer,
    statisticsData: statisticsState.reducer,
    surgeryData: surgeryState.reducer,
    tagsData: tagsState.reducer,
    notificationsData: notificationsState.reducer,
    thumbnailsData: thumbnailsState.reducer,
    photosData: photosState.reducer,
    trainerData: trainerState.reducer,
});

// Define root types encapsulating the whole store, all actions, and all thunks
export type RootState = ReturnType<typeof rootReducer>;
export type AppAction = ActionType<typeof surgeriesState.actions>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

// Create the store
const store = configureStore({
    reducer: rootReducer,
    // https://redux-toolkit.js.org/usage/usage-with-typescript#correct-typings-for-the-dispatch-type
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(createLoggerMiddleware<RootState, AppAction>()),
});

// Link in CMR Auth to the store
CMRAuth.configure(store, COGNITO);

export type AppDispatch = typeof store.dispatch;

export default store;
