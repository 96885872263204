import React, { useEffect } from "react";
import { usePageTitle } from "hooks";
import { Box, useTheme } from "@mui/system";
import {
    Avatar,
    ButtonBase,
    Card,
    CardActionArea,
    Chip,
    Grid,
    ImageListItem,
    ImageListItemBar,
    List,
    ListSubheader,
    Paper,
    Typography,
} from "@mui/material";
import { School, TimerOutlined } from "@mui/icons-material";
import useIsBreakpoint from "hooks/useIsBreakpoint";
import { Link } from "react-router-dom";
import { fixDurations } from "mixins/Utils";
import { zoomableCardActionArea, zoomableCardPaper } from "./../../resources/mui-prestyles";
import { useTypedDispatch, useTypedSelector } from "../../../hooks/store";
import { fetchVideos } from "../../../state/videos";
import getTheme from "mixins/GetTheme";

export interface ListItemType {
    id: number;
    date: string;
    time: string;
    elapsedTime: string;
    title: string;
    name: string;
    thumbnail: string;
}
export interface ListItemParentType {
    date: string;
    items: ListItemType[];
}

const VideoLibraryPage: React.FC = () => {
    usePageTitle("Video Library");
    const searchQuery = useTypedSelector((state) => state.appSettings.searchQuery);
    const theme = useTheme();
    const customTheme = getTheme(theme.palette.mode).custom;
    const isMdAndUp = useIsBreakpoint("md", ">");
    const imageHeight = isMdAndUp ? 200 : 120;

    const dispatch = useTypedDispatch();
    const videosData = useTypedSelector((state) => state.videosData);

    useEffect(() => {
        dispatch(fetchVideos());
    }, []);

    const [list, setList] = React.useState<ListItemParentType[]>([]);
    const listFromApi = [
        // TODO Ask about the correct keys:value-types of the list
        {
            date: "December 2020",
            items: [
                {
                    id: 4,
                    date: "12 Aug",
                    time: "01:20 PM",
                    elapsedTime: "415",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                },
                {
                    id: 5,
                    date: "18 Aug",
                    time: "05:20 PM",
                    elapsedTime: "415",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                },
                {
                    id: 6,
                    date: "25 Aug",
                    time: "12:20 AM",
                    elapsedTime: "415",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                },
            ],
        },
        {
            date: "November 2020",
            items: [
                {
                    id: 4,
                    date: "12 Aug",
                    time: "01:20 PM",
                    elapsedTime: "415",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                },
                {
                    id: 5,
                    date: "18 Aug",
                    time: "05:20 PM",
                    elapsedTime: "415",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                },
                {
                    id: 6,
                    date: "25 Aug",
                    time: "12:20 AM",
                    elapsedTime: "415",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                },
            ],
        },

        {
            date: "September 2020",
            items: [
                {
                    id: 4,
                    date: "12 Aug",
                    time: "01:20 PM",
                    elapsedTime: "415",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                },
                {
                    id: 5,
                    date: "18 Aug",
                    time: "05:20 PM",
                    elapsedTime: "415",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                },
                {
                    id: 6,
                    date: "25 Aug",
                    time: "12:20 AM",
                    elapsedTime: "415",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                },
                {
                    id: 4,
                    date: "12 Aug",
                    time: "01:20 PM",
                    elapsedTime: "415",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                },
                {
                    id: 5,
                    date: "18 Aug",
                    time: "05:20 PM",
                    elapsedTime: "415",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                },
                {
                    id: 6,
                    date: "25 Aug",
                    time: "12:20 AM",
                    elapsedTime: "415",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                },
            ],
        },
        {
            date: "August 2020",
            items: [
                {
                    id: 1,
                    date: "12 Aug",
                    time: "01:20 PM",
                    elapsedTime: "415",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                },
                {
                    id: 2,
                    date: "18 Aug",
                    time: "05:20 PM",
                    elapsedTime: "415",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                },
                {
                    id: 3,
                    date: "25 Aug",
                    time: "12:20 AM",
                    elapsedTime: "415",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                },
                {
                    id: 1,
                    date: "12 Aug",
                    time: "01:20 PM",
                    elapsedTime: "415",
                    title: "Intern Experience",
                    name: "DRY LAB",
                    thumbnail: "https://c.files.bbci.co.uk/8064/production/_104086823_surgery1.jpg",
                },
                {
                    id: 2,
                    date: "18 Aug",
                    time: "05:20 PM",
                    elapsedTime: "415",
                    title: "Intermediate Experience",
                    name: "WARM LAB",
                    thumbnail:
                        "https://ichef.bbci.co.uk/news/976/cpsprodpb/113C4/production/_118969507_gettyimages-678960833.jpg",
                },
                {
                    id: 3,
                    date: "25 Aug",
                    time: "12:20 AM",
                    elapsedTime: "415",
                    title: "High Experience",
                    name: "DOCO LAB",
                    thumbnail: "https://img.msf.org/AssetLink/iqo350560s61qnx8y52k617mfk7y4k1k.jpg",
                },
            ],
        },
    ];

    // Search query watcher
    React.useEffect(() => {
        const newList = listFromApi
            .map((parentItem) => {
                return {
                    date: parentItem.date,
                    items: parentItem.items.filter(
                        (item) =>
                            !searchQuery.trim() ||
                            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            item.name.toLowerCase().includes(searchQuery.toLowerCase())
                    ),
                };
            })
            .filter((parentItem) => parentItem.items.length > 0);
        setList(newList);
    }, [searchQuery]);

    return (
        <div>
            {list.map((period, i) => (
                <Box key={i}>
                    <List>
                        <ListSubheader
                            sx={{
                                backgroundColor: theme.palette.background.default,
                            }}
                        >
                            {period.date}
                        </ListSubheader>
                        <Grid container alignItems={"stretch"} spacing={isMdAndUp ? 3 : 1.5}>
                            {period.items.map((item, ii) => (
                                <Grid key={ii} item xs={12} sm={6} lg={4} xl={3}>
                                    <Card
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        component={Link}
                                        to={`/video-library/${item.id}`}
                                    >
                                        <Paper sx={{ height: "100%", ...zoomableCardPaper() }}>
                                            <CardActionArea sx={{ height: "100%" }}>
                                                <Grid
                                                    container
                                                    flexDirection={"column"}
                                                    height={"100%"}
                                                >
                                                    <Box marginBottom={-1}>
                                                        <ImageListItem sx={{ width: "100%" }}>
                                                            <Avatar
                                                                style={{
                                                                    width: "100%",
                                                                    height: imageHeight,
                                                                }}
                                                                variant="square"
                                                                src={item.thumbnail}
                                                            ></Avatar>
                                                            <ImageListItemBar
                                                                title="You've watched"
                                                                subtitle={`${fixDurations(
                                                                    item.elapsedTime
                                                                )} from this video`}
                                                            ></ImageListItemBar>
                                                        </ImageListItem>
                                                    </Box>
                                                    <Box
                                                        flexGrow={1}
                                                        sx={{
                                                            px: 2,
                                                            py: 2,
                                                        }}
                                                    >
                                                        <Grid container height={"100%"}>
                                                            <Box
                                                                display={"flex"}
                                                                rowGap={3}
                                                                flexDirection={"column"}
                                                                flexGrow={1}
                                                                justifyContent={"space-between"}
                                                            >
                                                                <Box>
                                                                    <Typography
                                                                        variant={
                                                                            isMdAndUp
                                                                                ? "body1"
                                                                                : "body2"
                                                                        }
                                                                    >
                                                                        {item.title}
                                                                    </Typography>
                                                                </Box>
                                                                <Box
                                                                    display={"flex"}
                                                                    flexDirection={"column"}
                                                                    rowGap={1}
                                                                >
                                                                    <Box
                                                                        display={"flex"}
                                                                        columnGap={1}
                                                                    >
                                                                        <Chip
                                                                            icon={
                                                                                <School
                                                                                    sx={{
                                                                                        color: `${customTheme.secondaryText} !important`,
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={item.name}
                                                                            size="small"
                                                                            sx={{
                                                                                bgcolor:
                                                                                    "transparent",
                                                                                columnGap: 1,
                                                                                color:
                                                                                    customTheme.secondaryText,
                                                                            }}
                                                                        ></Chip>
                                                                    </Box>
                                                                    <Box
                                                                        display={"flex"}
                                                                        columnGap={1}
                                                                    >
                                                                        <Chip
                                                                            icon={<TimerOutlined />}
                                                                            label={`${item.date} : ${item.time}`}
                                                                            size="small"
                                                                            sx={{
                                                                                bgcolor:
                                                                                    "transparent",
                                                                                columnGap: 1,
                                                                            }}
                                                                        ></Chip>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </CardActionArea>
                                        </Paper>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </List>
                </Box>
            ))}
        </div>
    );
};

export default VideoLibraryPage;
