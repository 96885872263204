import React from "react";
import { usePageTitle } from "hooks";
import { Button, IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import SendVerifyCodeDialog, { dialogCloseState } from "./SendVerifyCodeDialog";
import ShadowButton from "../../components/Utils/ShadowButton";
interface SettingsErrorsType {
    currentPassword: string;
    newPassword: string;
}

const ChangePassword: React.FC = () => {
    usePageTitle("Change Password");
    const [errors, setErrors] = React.useState<SettingsErrorsType>({
        currentPassword: "",
        newPassword: "",
    });
    const theme = useTheme();
    const username = "testuserpr@gmail.com"; // Fixed - Will be fetched from an API
    const [currentPassword, setCurrentPassword] = React.useState({
        value: "",
        show: false,
    });
    const [newPassword, setNewPassword] = React.useState({
        value: "",
        show: false,
    });
    const [confirmNewPassword, setConfirmNewPassword] = React.useState({
        value: "",
        show: false,
    });
    const [verificationCode, setVerificationCode] = React.useState({
        value: "",
        show: true,
    });
    const [userHasCode, setUserHasCode] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = (type: dialogCloseState) => {
        if (["got-code", "send"].includes(type)) {
            setUserHasCode(true);
        }
        setDialogOpen(false);
    };
    const fields = [
        {
            id: "username",
            label: "Username",
            value: username,
            show: false, // show: has no effect on username visibility state but must be false to hide toggleVisibilty button
            type: "text",
            helperText: "",
            readOnly: true,
            remove: false,
            error: false,
            onChange: () => {
                return;
            },
        },
        {
            id: "current-password",
            label: "Current Password",
            ...currentPassword,
            type: "password",
            helperText: (
                <Box sx={{ display: "flex", justifyContent: "space-between" }} component="span">
                    <span>{errors.currentPassword}</span>
                    <Button
                        variant="text"
                        sx={{ textTransform: "initial" }}
                        onClick={handleDialogOpen}
                    >
                        Forgot Password?
                    </Button>
                </Box>
            ),
            readOnly: false,
            remove: userHasCode,
            error: errors.currentPassword ? true : false,
            onChange: setCurrentPassword,
        },
        {
            id: "new-password",
            label: "New Password",
            ...newPassword,
            type: "password",
            helperText:
                errors.newPassword ||
                "Minimum 6 characters, must contain a special character, a number, and an uppercase and lowercase letter",

            readOnly: false,
            remove: false,
            error: errors.newPassword ? true : false,
            onChange: setNewPassword,
        },
        {
            id: "confirm-new-password",
            label: "Confirm New Password",
            ...confirmNewPassword,
            type: "password",
            helperText: "",
            readOnly: false,
            remove: false,
            error: false,
            onChange: setConfirmNewPassword,
        },
        {
            // Just in case user already has a verification code
            id: "verification-code",
            label: "Enter your verification code",
            ...verificationCode,
            type: "password",
            helperText: "",
            readOnly: false,
            remove: !userHasCode,
            error: false,
            onChange: setVerificationCode,
        },
    ];
    // Computed
    const canSubmitForm: () => boolean = () => {
        if (
            newPassword.value.trim().length >= 6 &&
            confirmNewPassword.value.trim() === newPassword.value.trim() &&
            ((!userHasCode && currentPassword.value.trim().length >= 6) ||
                (userHasCode && verificationCode.value.trim().length >= 6))
        ) {
            return true;
        } else {
            return false;
        }
    };
    // Methods
    const submitForm = (e: React.SyntheticEvent) => {
        e.preventDefault();
        // TODO Create a store reducer to submit this form
        if (userHasCode) {
            // Reset password using a verfication code
        } else {
            // Reset password using the old password
        }
    };
    // Watchers
    React.useEffect(() => {
        // Check if newPassword.value meets the required specifications
        let newPasswordError = "";
        const v = newPassword.value;
        if (v.length > 0) {
            if (v.length < 6) {
                newPasswordError = "Minimum 6 characters";
            } else if (!/[a-z]/.test(v) || !/[A-Z]/.test(v)) {
                newPasswordError = "Must contain an uppercase and lowercase letter";
            } else if (!/[0-9]/.test(v)) {
                newPasswordError = "Must contain a number";
            } else if (!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(v)) {
                newPasswordError = "Must contain a special character";
            }
        }
        const newErrorsObj = { ...errors };
        newErrorsObj.newPassword = newPasswordError;
        setErrors(newErrorsObj);
    }, [newPassword.value]);
    return (
        <Box maxWidth={"sm"} mx={"auto"}>
            <form onSubmit={submitForm}>
                <Box marginBottom={2}>
                    {fields.map(
                        (field, i) =>
                            !field.remove && (
                                <TextField
                                    key={i}
                                    label={field.label}
                                    type={field.show ? "text" : field.type}
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    error={field.error}
                                    helperText={field.helperText}
                                    InputProps={{
                                        readOnly: field.readOnly,
                                        endAdornment: (field.show || field.type === "password") && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        field.onChange({
                                                            value: field.value,
                                                            show: !field.show,
                                                        })
                                                    }
                                                    onMouseDown={(e) => e.preventDefault()}
                                                >
                                                    {field.show ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={field.value}
                                    onChange={(e) =>
                                        field.onChange({ value: e.target.value, show: field.show })
                                    }
                                ></TextField>
                            )
                    )}
                </Box>
                <Box>
                    <ShadowButton
                        variant="contained"
                        disabled={!canSubmitForm()}
                        fullWidth
                        size="large"
                        sx={{ py: 2, textTransform: "initial" }}
                        type="submit"
                        color="primary"
                    >
                        Change Password
                    </ShadowButton>
                </Box>
            </form>
            <SendVerifyCodeDialog open={dialogOpen} onClose={handleDialogClose} />
        </Box>
    );
};

export default ChangePassword;
