import { createTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { themeShadows } from "./Utils";

const darkTheme: Theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#66B2FF",
            contrastText: "#fff",
        },
        secondary: {
            main: "#001e3c",
            contrastText: "#fff",
        },
        background: {
            default: "#0a1929",
            paper: "#001e3c",
        },
        common: {
            //rgba(101,108,133,0.8)
        },
        text: {
            secondary: "#7e92a7",
            disabled: "rgba(255, 255, 255, 0.4)",
        },
        divider: "rgba(255, 255, 255, 0.12)",
    },
    shadows: themeShadows({ 1: "0 0 3px rgba(102, 178, 255, 0.4)" }),
});

const customTheme = {
    primaryText: "#66B2FF",
    secondaryText: "#e10972",
    textOnPrimaryColor: "#fff",
    textOnSecondaryColor: "#a5a5a5",
};

const obj = { theme: darkTheme, custom: customTheme };

export default obj;
