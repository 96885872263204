import { IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import ModalOutline from ".";

export interface DataObjectType {
    title?: "";
    actionText?: "";
    text?: "";
}

const InfoModal = (props: { open: boolean; onClose: any; data: DataObjectType }) => {
    return (
        <ModalOutline
            open={props.open}
            title={props.data.title}
            submitText={props.data.actionText || "OK"}
            dense
            submitVariant="text"
            onClose={props.onClose}
        >
            <Typography variant="body2">{props.data.text}</Typography>
        </ModalOutline>
    );
};

export default InfoModal;
