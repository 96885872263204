import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";

const name = "videos";

export const initialState = {
    isLoading: false,
    error: "",
    videos: [],
    videoStream: {},
    currentVideo: {},
    videoMarkers: [],
};

export const fetchVideos = createAsyncThunk("videos/fetch", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/videos`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const fetchVideoStream = createAsyncThunk(
    "videos/fetchStream",
    async (id: string, thunkApi) => {
        try {
            const config = await tokenHeader();
            const resp = await axios.get(`${baseUrl}/${id}/stream_url`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const fetchVideoById = createAsyncThunk("videos/fetchId", async (id: string, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/${id}`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const fetchVideoMarkers = createAsyncThunk(
    "videos/fetchMarkers",
    async (id: string, thunkApi) => {
        try {
            const config = await tokenHeader();
            const resp = await axios.get(`${baseUrl}/${id}/markers`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const isAnyOf = (...matchers: Array<string | { type: string }>) => (action: AnyAction) =>
    matchers.some((matcher) =>
        typeof matcher === "string" ? matcher === action.type : matcher.type === action.type
    );

const videoSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Fetch videos
        builder.addCase(fetchVideos.fulfilled, (state, action) => {
            state.isLoading = false;
            state.videos = action.payload;
        });

        //Fetch video stream
        builder.addCase(fetchVideoStream.fulfilled, (state, action) => {
            state.isLoading = false;
            state.videoStream = action.payload;
        });

        //Fetch video by id
        builder.addCase(fetchVideoById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.currentVideo = action.payload;
        });

        //Fetch video markers
        builder.addCase(fetchVideoMarkers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.videoMarkers = action.payload;
        });

        builder.addMatcher(
            isAnyOf(
                fetchVideos.pending,
                fetchVideoStream.pending,
                fetchVideoById.pending,
                fetchVideoMarkers.pending
            ),
            (state) => {
                state.isLoading = true;
            }
        );

        builder.addMatcher(
            isAnyOf(
                fetchVideos.rejected,
                fetchVideoStream.rejected,
                fetchVideoById.rejected,
                fetchVideoMarkers.rejected
            ),
            (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            }
        );
    },
});

export default videoSlice;
