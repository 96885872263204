import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";
import { ProcedureI } from "./types";

const name = "procedures";

export const initialState = {
    isLoading: false,
    error: "",
    procedures: [],
};

export const fetchProcedures = createAsyncThunk(
    "procedures/fetch",
    async (data: ProcedureI, thunkApi) => {
        try {
            const config = await tokenHeader(data);
            const resp = await axios.get(`${baseUrl}/procedures`, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const proceduresSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProcedures.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(fetchProcedures.fulfilled, (state, action) => {
            state.isLoading = false;
            state.procedures = action.payload;
        });

        builder.addCase(fetchProcedures.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as string;
        });
    },
});

export default proceduresSlice;
