import _ from "lodash";
import redux from "redux";
import { detect } from "detect-browser";
import SumoLogger from "sumo-logger";
import { Action } from "typesafe-actions";

import { LOGGING } from "./config";

const logger = new SumoLogger({
    endpoint: LOGGING.sumoLogicEndpoint,
    // Send logs to Sumo Logic once a second (without doing this, quick successive calls to log() don't seem to go through)
    interval: 1000,
});

// Ensure log messages are flushed prior to quitting/refreshing the app
window.addEventListener("beforeunload", () => logger.flushLogs());

type LogAction = {
    type: string;
    payload?: unknown;
};

export const log = (message: string, rest?: Record<string, unknown>): void => {
    const rawLog = {
        message,
        browser: detect(),
        location: window.location,
        // The package seems to always provide a blank "url". Let's at least make it useful
        url: window.location.href,
    };
    const logJson = rest ? { ...rawLog, ...rest } : rawLog;
    logger.log(logJson);
};

export const logAction = (action: LogAction, state: Record<string, unknown> | unknown[]): void => {
    log(`Redux action: ${action.type}`, { action, state });
};

type ActionCreator<TAction> = (...args: never[]) => TAction;
// TODO find a way to restrict this to the nested properties of TState
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type StateProperty<TState> = string;

type BlacklistedAction<TAction> = {
    action: ActionCreator<TAction>;
    properties: string[];
};
type ReduxLoggerBlacklist<TState, TAction> = {
    blacklistedActions?: BlacklistedAction<TAction>[];
    blacklistedStateProperties?: StateProperty<TState>[];
};

/**
 * Create Redux middleware that log all actions and pre-action state, excluding those provided to
 * the function as a blacklist.
 *
 * The generic types used in this function restrict the blacklist such that only valid actions
 * (actually, action creators) and state properties can be provided.
 *
 * Example usage:
 *
 * ```
 * createLoggerMiddleware<RootState, RootAction>({
 *     blacklistedActions: [
 *         // Don't log this action at all
 *         { action: authActions.getSession.request, properties: []
 *         // Log this action, excluding it's payload property "email"
 *         { action: authActions.getSession.success, properties: ["payload.email"]
 *     ],
 *     blacklistedStateProperties: ["auth.userName"]
 * })
 * ```
 *
 * Where `RootAction` is a union type of all possible actions in the application.
 * */
export const createLoggerMiddleware = <TState, TAction extends Action>(
    config?: ReduxLoggerBlacklist<TState, TAction>
): redux.Middleware => {
    // Only generate the blacklisted action types once per application run
    const blacklistedTypes: Record<string, string[]> =
        config?.blacklistedActions?.reduce(
            (map, { action, properties }) => ({ ...map, [action().type]: properties }),
            {}
        ) || {};
    const blacklistedStateProperties = config?.blacklistedStateProperties || [];

    // Return the actual middleware that simply logs if action.type not in blacklist
    return (store) => (next) => (action: TAction) => {
        const blacklistedProperties = blacklistedTypes[action.type];

        if (blacklistedProperties && blacklistedProperties.length === 0) return next(action);

        const trimmedState = _.omit(store.getState(), blacklistedStateProperties);

        const trimmedAction = blacklistedProperties
            ? (_.omit(action, blacklistedProperties) as TAction)
            : action;
        logAction(trimmedAction, trimmedState);
        return next(action);
    };
};
