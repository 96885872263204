import {
    FastForward,
    FastRewind,
    FirstPage,
    Home,
    LastPage,
    Pause,
    PlayArrow,
    Start,
} from "@mui/icons-material";
import React from "react";
import VREPlayer from "videojs-react-enhanced";
import ReactDOM from "react-dom";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
const VideojsCustomPlugin = (player: any, canPlayNext: boolean) => {
    // const el: any = document.getElementById("vjs_video_3");
    // const videoEl: HTMLVideoElement = el.children[0];
    const el: HTMLDivElement = player.el_;
    const videoEl: HTMLVideoElement = player.children_[0];
    const seek = (secs: number) => {
        let time = videoEl.currentTime + secs;
        if (time < 0) {
            time = 0;
        } else if (time > videoEl.duration) {
            time = videoEl.duration;
        }
        videoEl.currentTime = time;
    };
    const forward = () => {
        seek(15);
    };
    const rewind = () => {
        seek(-5);
    };
    const restart = () => {
        seek(-videoEl.currentTime);
    };
    const next = () => {
        seek(videoEl.duration);
    };
    // Container to hold buttons
    const container = (
        <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            columnGap={12}
        >
            <Box>
                <IconButton size="large">
                    <FirstPage fontSize="large" onClick={() => restart()} />
                </IconButton>
                <IconButton size="large">
                    <FastRewind fontSize="large" onClick={() => rewind()} />
                </IconButton>
            </Box>
            <Box>
                <IconButton size="large">
                    <FastForward fontSize="large" onClick={() => forward()} />
                </IconButton>
                <IconButton size="large" disabled={!canPlayNext}>
                    <LastPage fontSize="large" onClick={() => next()} />
                </IconButton>
            </Box>
        </Box>
    );
    // Use a fake container to prevent ReactDOM.render() issues
    const fakeContainer = document.createElement("div");
    fakeContainer.classList.add("vjs-control-bar");
    fakeContainer.classList.add("vjs-custom-control-bar");
    el.append(fakeContainer);
    ReactDOM.render(container, fakeContainer);

    // Change volumn meter to vertical instead of horizontal
    // const vMeterContainer = document.getElementsByClassName("vjs-volume-panel");
    // if (vMeterContainer && vMeterContainer.length > 0) {
    //     vMeterContainer[0].classList.remove("vjs-volume-panel-horizontal");
    //     vMeterContainer[0].classList.add("vjs-volume-panel-vertical");
    //     const vControl = vMeterContainer[0].getElementsByClassName("vjs-volume-control");
    //     if (vControl && vControl.length > 0) {
    //         vControl[0].classList.remove("vjs-volume-horizontal");
    //         vControl[0].classList.add("vjs-volume-vertical");
    //         const vBar = vControl[0].getElementsByClassName("vjs-volume-bar");
    //         if (vBar && vBar.length > 0) {
    //             vBar[0].classList.remove("vjs-slider-horizontal");
    //             vBar[0].classList.add("vjs-slider-vertical");
    //         }
    //     }
    // }
};

export default VideojsCustomPlugin;
