import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "mixins/GetTheme";

type ThemeNameType = "light" | "dark";
const prefersDarkModeCustom = (): boolean => {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return true;
    } else {
        return false;
    }
};
const CustomThemeContextDefault: {
    currentTheme: ThemeNameType;
    setTheme: (name: ThemeNameType) => void;
} = {
    currentTheme: prefersDarkModeCustom() ? "dark" : "light",
    setTheme() {
        return;
    },
};
export const CustomThemeContext = React.createContext(CustomThemeContextDefault);

const CustomThemeProvider = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const getCurrentTheme = (): ThemeNameType => {
        const prefersDarkMode = prefersDarkModeCustom();
        const ct = localStorage.getItem("appTheme");
        const v: ThemeNameType = "light";
        if (!ct) {
            return prefersDarkMode ? "dark" : "light";
        } else if (ct === "light" || ct === "dark") {
            return ct;
        } else {
            return v;
        }
    };
    const currentTheme = getCurrentTheme() || "light";
    const [themeName, _setThemeName] = React.useState(currentTheme);

    const theme = getTheme(themeName).theme;

    const setThemeName = (name: ThemeNameType) => {
        localStorage.setItem("appTheme", name);
        _setThemeName(name);
        document.documentElement.setAttribute("data-mode", name);
    };

    React.useEffect(() => {
        const themeName = getCurrentTheme();
        setThemeName(themeName);
    }, []);

    const contextValue = {
        currentTheme: themeName,
        setTheme: setThemeName,
    };

    return (
        <CustomThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CustomThemeContext.Provider>
    );
};

export default CustomThemeProvider;
