import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";
import { CreateBookmarkI } from "./types";

const name = "bookmarks";

export const initialState = {
    isLoading: false,
    error: "",
    bookmarks: [],
    bookmark: {},
};

export const createBookmark = createAsyncThunk(
    "bookmark/create",
    async (data: CreateBookmarkI, thunkApi) => {
        try {
            console.log(data, "data");
            const config = await tokenHeader();
            const resp = await axios.post(`${baseUrl}/bookmarks`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const fetchBookmarks = createAsyncThunk("bookmark/fetch", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/bookmarks`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const fetchBookmarkById = createAsyncThunk("bookmark/fetchId", async (id, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/bookmarks/${id}`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const deleteBookmark = createAsyncThunk("bookmark/delete", async (id: string, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.delete(`${baseUrl}/bookmarks/${id}`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.response.data.detail);
    }
});

export const updateBookmark = createAsyncThunk(
    "bookmark/update",
    async (data: CreateBookmarkI, thunkApi) => {
        try {
            const id = data.bookmarkId;
            delete data.bookmarkId;
            const config = await tokenHeader();
            const resp = await axios.put(`${baseUrl}/bookmarks/${id}`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const isAnyOf = (...matchers: Array<string | { type: string }>) => (action: AnyAction) =>
    matchers.some((matcher) =>
        typeof matcher === "string" ? matcher === action.type : matcher.type === action.type
    );

const bookmarkSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Create bookmark
        builder.addCase(createBookmark.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.bookmarks = [action.payload, ...state.bookmarks];
            console.log(action.payload, "action.payload");
        });

        //Fetch bookmark
        builder.addCase(fetchBookmarks.fulfilled, (state, action) => {
            state.isLoading = false;
            state.bookmarks = action.payload;
        });

        //Fetch bookmark by id
        builder.addCase(fetchBookmarkById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.bookmark = action.payload;
        });

        //Delete bookmark by id
        builder.addCase(deleteBookmark.fulfilled, (state, action) => {
            state.isLoading = false;
            state.bookmark = action.payload;
        });

        //Update bookmark
        builder.addCase(updateBookmark.fulfilled, (state, action) => {
            state.isLoading = false;
            state.bookmark = action.payload;
        });

        builder.addMatcher(
            isAnyOf(
                createBookmark.pending,
                fetchBookmarks.pending,
                fetchBookmarkById.pending,
                deleteBookmark.pending,
                updateBookmark.pending
            ),
            (state) => {
                state.isLoading = true;
            }
        );

        builder.addMatcher(
            isAnyOf(
                createBookmark.rejected,
                fetchBookmarks.rejected,
                fetchBookmarkById.rejected,
                deleteBookmark.rejected,
                updateBookmark.rejected
            ),
            (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            }
        );
    },
});

export default bookmarkSlice;
