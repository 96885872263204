import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notificationsUrl, tokenHeader } from "../../config";

const name = "notifications";

export const initialState = {
    isLoading: false,
    error: "",
    notifications: [],
};

export const addNotifications = createAsyncThunk(
    "notifications/add",
    async (id: string, thunkApi) => {
        try {
            const data = {
                deviceProtocol: "apn",
                deviceId: id,
            };
            const config = await tokenHeader(data);
            const resp = await axios.post(
                `${notificationsUrl}/v1/targets/versius_connect_dev/register`,
                data,
                config
            );
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const notificationsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addNotifications.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(addNotifications.fulfilled, (state, action) => {
            state.isLoading = false;
            state.notifications = action.payload;
        });

        builder.addCase(addNotifications.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as string;
        });
    },
});

export default notificationsSlice;
