/*
  Empty modal outline
  -- created to be used as a start point for other custom modals --
*/

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Backdrop,
    CircularProgress,
    TextField,
} from "@mui/material";
import React from "react";
import ShadowButton from "../Utils/ShadowButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, width } from "@mui/system";

export interface SimpleDialogProps {
    open: boolean;
    onClose: (value: string) => void;
}

export type dialogCloseState = "cancel" | "submit" | "third-action" | string;
type ButtonVariant = "text" | "contained" | "outlined";

const SlideTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalOutline = (props: {
    children?: React.ReactNode;
    // Attributes
    open: boolean;
    title?: string;
    width?: number | string;
    loading?: boolean;
    cancelable?: boolean;
    disableSubmit?: boolean;
    cancelText?: string;
    submitText?: string;
    thirdAction?: boolean;
    thirdActionText?: string;
    thirdActionColor?:
        | "inherit"
        | "primary"
        | "secondary"
        | "success"
        | "error"
        | "info"
        | "warning";
    dense?: boolean;
    submitVariant?: ButtonVariant;
    cancelVariant?: ButtonVariant;
    thirdActionVariant?: ButtonVariant;
    // Methods
    onClose: any;
    onCancel?: any;
    onSubmit?: any;
    onThirdAction?: any;
}) => {
    const hideModal = (x: boolean) => {
        x && props.onClose && props.onClose();
    };
    const handleClose = async (type: dialogCloseState) => {
        switch (type) {
            case "submit":
                const x1 = props.onSubmit ? await props.onSubmit() : true;
                hideModal(x1);
                break;
            case "cancel":
                props.onCancel && (await props.onCancel());
                hideModal(true);
                break;
            case "third-action":
                const x3 = props.onThirdAction ? await props.onThirdAction() : true;
                hideModal(x3);
                break;
            default:
                hideModal(true);
                break;
        }
    };
    return (
        <Dialog
            open={props.open}
            scroll="paper"
            TransitionComponent={SlideTransition}
            sx={{
                maxWidth: `${props.width || "500"}px`,
                mx: "auto",
            }}
            fullWidth
            onClose={() => handleClose("cancel")}
        >
            <DialogTitle sx={{ pt: 3 }}>{props.title}</DialogTitle>
            <DialogContent>
                <Box pt={props.dense ? 0 : 3} display={"flex"} flexDirection="column" rowGap={3}>
                    {props.children}
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    px: 3,
                    pb: 3,
                    pt: 2,
                    justifyContent: props.thirdAction ? "flex-start" : "flex-end",
                    columnGap: 2,
                }}
            >
                {props.thirdAction && (
                    <>
                        <Button
                            variant={props.thirdActionVariant || "text"}
                            size="large"
                            color={props.thirdActionColor}
                            sx={{ textTransform: "initial", marginLeft: "0 !important" }}
                            onClick={() => handleClose("third-action")}
                        >
                            {props.thirdActionText || "Remove"}
                        </Button>
                        <Box sx={{ flexGrow: 1 }}></Box>
                    </>
                )}
                {props.cancelable && (
                    <Button
                        variant={props.cancelVariant || "outlined"}
                        size="large"
                        sx={{ textTransform: "initial", marginLeft: "0 !important" }}
                        onClick={() => handleClose("cancel")}
                    >
                        {props.cancelText || "Cancel"}
                    </Button>
                )}
                <ShadowButton
                    variant={props.submitVariant || "contained"}
                    size="large"
                    disabled={props.disableSubmit}
                    sx={{ textTransform: "initial", marginLeft: "0 !important", px: 4 }}
                    onClick={() => handleClose("submit")}
                >
                    {props.submitText || "Submit"}
                </ShadowButton>
            </DialogActions>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.loading || false}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
};

export default ModalOutline;
