import React, { useEffect, useMemo } from "react";
import { usePageTitle } from "../../../hooks";
import { fetchTerms } from "../../../state/settings";
import { useTypedDispatch, useTypedSelector } from "../../../hooks/store";

const TermsConditions = () => {
    usePageTitle("Terms & Conditions");
    const dispatch = useTypedDispatch();
    const termsData = useTypedSelector((state) => state.termsData);

    useEffect(() => {
        dispatch(fetchTerms());
    }, []);

    const str = useMemo(() => {
        return termsData.terms.agreement.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }, [termsData.terms.agreement]);

    return <div dangerouslySetInnerHTML={{ __html: str }} />;
};

export default TermsConditions;
