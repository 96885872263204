import React from "react";
import { TransitionGroup, CSSTransition, SwitchTransition } from "react-transition-group";
import AppNavbar from "../ui/components/AppNavbar";
import TrainerIconIOS from "../ui/resources/svg/trainer.svg";
import VideoLibraryIconIOS from "../ui/resources/svg/video-library.svg";
import LogbookIconIOS from "../ui/resources/svg/logbook.svg";
import StatsIconIOS from "../ui/resources/svg/stats.svg";
import SettingsIconIOS from "../ui/resources/svg/settings.svg";
import BottomNavigation from "../ui/components/AppNavbar/BottomNavigation";
import useIsBreakpoint from "../hooks/useIsBreakpoint";
import { Helmet } from "react-helmet";

const MainLayout = (props: any) => {
    const [links] = React.useState<
        {
            label: string;
            to: string;
            icon: string;
        }[]
    >([
        {
            label: "Trainer",
            icon: TrainerIconIOS,
            to: "/trainer",
        },
        {
            label: "Video library",
            icon: VideoLibraryIconIOS,
            to: "/video-library",
        },
        {
            label: "Logbook",
            icon: LogbookIconIOS,
            to: "/logbook",
        },
        {
            label: "Stats",
            icon: StatsIconIOS,
            to: "/stats",
        },
        {
            label: "Settings",
            icon: SettingsIconIOS,
            to: "/settings",
        },
    ]);

    const isMdAndUp = useIsBreakpoint("md", ">");

    return (
        <>
            <Helmet>
                {/* Scoped style for root container on main-layout only */}
                <style>
                    {`
                        #root {
                            justify-content: flex-end;
                        }
                    `}
                </style>
            </Helmet>
            <AppNavbar links={links} />
            <TransitionGroup>
                <SwitchTransition mode="out-in">
                    <CSSTransition timeout={300} classNames="fade" key={props.name}>
                        <main>{props.children}</main>
                    </CSSTransition>
                </SwitchTransition>
            </TransitionGroup>
            {!isMdAndUp && <BottomNavigation links={links} />}
        </>
    );
};

export default MainLayout;
