import React from "react";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MainLayout from "../layout/MainLayout";
import CMRAuthRoute from "./components/CMRAuthRoute";
import LandingPage from "ui/pages/LandingPage";
import SurgeryListPage from "ui/pages/SurgeryListPage";
import SurgeryDetailPage from "ui/pages/SurgeryDetailPage";
import LogbookPage from "./pages/Logbook/index";
import VideoLibraryPage from "./pages/VideoLibrary/index";
import VideoLibraryItem from "./pages/VideoLibrary/_id/index";
import VideoLibraryItemDetails from "./pages/VideoLibrary/_id/Details";
import SettingsPage from "./pages/settings/index";
import MyProfile from "./pages/settings/MyProfile";
import ChangePassword from "./pages/settings/ChangePassword";
import TermsConditions from "./pages/settings/TermsConditions";
import StatsPage from "./pages/Stats/index";
import TrainerPage from "./pages/Trainer/index";
import QrCodeScannerPage from "./pages/Trainer/Scanner/index";
import QrCodeScannedPage from "./pages/Trainer/Scanner/_qrcode";
import Login from "./pages/Login";
import useIsBreakpoint from "hooks/useIsBreakpoint";

import "./App.css";
import "./resources/scss/index.scss";

const AppContent: React.FC = () => {
    const location = useLocation();
    const isSmAndUp = useIsBreakpoint("sm", ">");
    const isMdAndUp = useIsBreakpoint("md", ">");
    const isLgAndUp = useIsBreakpoint("lg", ">");
    const isXlAndUp = useIsBreakpoint("xl", ">");
    const containerMaxWidth = () => {
        if (isXlAndUp) {
            return "lg";
        } else if (isLgAndUp) {
            return "md";
        } else if (isMdAndUp) {
            return "sm";
        } else if (isSmAndUp) {
            return "xs";
        } else {
            return "xs";
        }
    };
    return (
        <Container
            sx={{
                mt: 8,
                mb: 12,
                pt: 3,
            }}
            maxWidth={containerMaxWidth()}
        >
            <Switch location={location}>
                <Route path="/" exact>
                    {CMRAuthRoute(LandingPage)}
                </Route>

                <Route path="/login" exact>
                    {CMRAuthRoute(Login)}
                </Route>

                <Route path="/logbook" exact>
                    <MainLayout name="Logbook">{CMRAuthRoute(LogbookPage)}</MainLayout>
                </Route>

                <Route path="/settings" exact>
                    <MainLayout name="SettingsPage">{CMRAuthRoute(SettingsPage)}</MainLayout>
                </Route>

                <Route path="/settings/terms-conditions" exact>
                    <MainLayout name="TermsConditions">{CMRAuthRoute(TermsConditions)}</MainLayout>
                </Route>

                <Route path="/trainer" exact>
                    <MainLayout name="TrainerPage">{CMRAuthRoute(TrainerPage)}</MainLayout>
                </Route>

                <Route path="/trainer/scan" exact>
                    <MainLayout name="QrCodeScannerPage">
                        {CMRAuthRoute(QrCodeScannerPage)}
                    </MainLayout>
                </Route>

                <Route path="/trainer/scan/:qrCode" exact>
                    <MainLayout name="QrCodeScannedPage">
                        {CMRAuthRoute(QrCodeScannedPage)}
                    </MainLayout>
                </Route>

                <Route path="/stats" exact>
                    <MainLayout name="StatsPage">{CMRAuthRoute(StatsPage)}</MainLayout>
                </Route>

                <Route path="/video-library" exact>
                    <MainLayout name="VideoLibraryPage">
                        {CMRAuthRoute(VideoLibraryPage)}
                    </MainLayout>
                </Route>

                <Route path="/video-library/:id" exact>
                    <MainLayout name="VideoLibraryItem">
                        {CMRAuthRoute(VideoLibraryItem)}
                    </MainLayout>
                </Route>

                <Route path="/video-library/:id/details/:showVideoCard?" exact>
                    <MainLayout name="VideoLibraryItemDetails">
                        {CMRAuthRoute(VideoLibraryItemDetails)}
                    </MainLayout>
                </Route>

                <Route path="/settings/my-profile" exact>
                    <MainLayout name="MyProfile">{CMRAuthRoute(MyProfile)}</MainLayout>
                </Route>

                <Route path="/settings/my-profile/change-password" exact>
                    <MainLayout name="ChangePassword">{CMRAuthRoute(ChangePassword)}</MainLayout>
                </Route>

                <Route path="/surgeries" exact>
                    <MainLayout name="SurgeryListPage">{CMRAuthRoute(SurgeryListPage)}</MainLayout>
                </Route>

                <Route path="/surgeries/:surgeryKey" exact>
                    <MainLayout name="SurgeryDetailPage">
                        {CMRAuthRoute(SurgeryDetailPage)}
                    </MainLayout>
                </Route>

                {/* Currently, We don't have a landing page, So, replace history to logbook */}
                {/*<Redirect to="/logbook" />*/}
            </Switch>
        </Container>
    );
};

const App: React.FC = () => {
    const isMdAndUp = useIsBreakpoint("md", ">");
    return (
        <BrowserRouter>
            <CssBaseline />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMdAndUp ? "initial" : "column",
                    minHeight: isMdAndUp ? "initial" : "100vh",
                }}
            >
                <AppContent />
            </Box>
        </BrowserRouter>
    );
};

export default App;
