import { Box } from "@mui/material";
import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router-dom";
import ErrorModal from "ui/components/Modals/ErrorModal";

const Scanner: React.FC = () => {
    const history = useHistory();
    const [delay, setDelay] = useState(100);
    const [result, setResult] = React.useState("");
    const [errorModal, setErrorModal] = React.useState(false);
    const [errorObject, setErrorObject] = React.useState({});
    const handleScan = (qrcode: string | null) => {
        if (qrcode !== null && qrcode !== "null") {
            history.push(`/trainer/scan/${qrcode}`);
        }
    };
    const handleError = (e: any) => {
        console.log(e);
        if (e.toString().includes("Requested device not found")) {
            handleScan("this-is-a-scanned-qr-code"); // temp code
            setErrorObject({
                title: "Unable to open camera",
                text:
                    "App is unable to open your camera to scan QR code, Can you please allow app to access your device camera?",
            });
            setErrorModal(true);
        }
    };
    return (
        <div>
            <QrReader
                delay={delay}
                onError={handleError}
                onScan={handleScan}
                className="qr-reader"
            ></QrReader>
            {/* <Box textAlign={"center"}>Scan the QR code shown on the Versius screen</Box> */}
            <ErrorModal
                errorObject={errorObject}
                open={errorModal}
                onClose={() => {
                    setErrorModal(false);
                    history.push("/trainer");
                }}
            ></ErrorModal>
        </div>
    );
};

export default Scanner;
