import React from "react";

import { Surgery, SurgeryLocation } from "state/surgeries/types";

const formatLocation = (location: SurgeryLocation | null) =>
    location ? `${location.name} - ${location.city}, ${location.country}` : "UNKNOWN";

const formatSurgery = (surgery: Surgery) =>
    [
        `start: ${surgery.startTime}`,
        `duration: ${surgery.durationMins} mins`,
        `key: ${surgery.surgeryKey}`,
        `location: ${formatLocation(surgery.location)}`,
    ].join(" | ");

export interface SurgeryListItemProps {
    surgery: Surgery;
}
const SurgeryListItem: React.FC<SurgeryListItemProps> = ({ surgery }) => (
    <>{formatSurgery(surgery)}</>
);

export default SurgeryListItem;
