import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

const name = "appSettings";

interface AppSettings {
    pageTitle: string;
    drawerOpen: boolean | undefined;
    searchQuery: string;
}

const initialState: AppSettings = {
    pageTitle: "",
    drawerOpen: true,
    searchQuery: "",
};

export const appSettingsSlice = createSlice({
    name,
    initialState,
    reducers: {
        updatePageTitle: (state, action: PayloadAction<string>) => {
            state.pageTitle = action.payload;
        },
        handleDrawerState: (state, action: PayloadAction<boolean | undefined>) => {
            state.drawerOpen = action.payload;
        },
        updateSearchQuery: (state, action: PayloadAction<string>) => {
            state.searchQuery = action.payload;
        },
    },
});

export const { updatePageTitle, handleDrawerState, updateSearchQuery } = appSettingsSlice.actions;

export const getPageTitle = (state: RootState): string => state.appSettings.pageTitle;
export const getDrawerState = (state: RootState): boolean | undefined =>
    state.appSettings.drawerOpen;
export const getSearchQuery = (state: RootState): string => state.appSettings.searchQuery;

export default appSettingsSlice;
