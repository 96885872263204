import { createTheme } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { themeShadows } from "./Utils";

const lightTheme: Theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#6bc2b8",
            contrastText: "#fff",
        },
        secondary: {
            main: "#221c34",
            contrastText: "#fff",
        },
        background: {
            default: "#f3f6f9",
        },
        text: {
            disabled: "rgba(0, 0, 0, 0.25)",
        },
    },
    shadows: themeShadows({ 1: "0px 4px 20px rgba(61, 71, 82, 0.08)" }),
});

const customTheme = {
    primaryText: "#6bc2b8",
    secondaryText: "#e6117b",
    textOnPrimaryColor: "#fff",
    textOnSecondaryColor: "#fff",
};

const obj = { theme: lightTheme, custom: customTheme };

export default obj;
