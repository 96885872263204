import React from "react";

import { usePageTitle } from "hooks";

const LandingPage: React.FC = () => {
    usePageTitle("Home");
    return <></>;
};

export default LandingPage;
