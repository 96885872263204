import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl, tokenHeader } from "../../config";
import axios from "axios";

const name = "users";

interface UserData {
    name: string;
    email: string;
    id?: string;
}

const initialState = {
    isLoading: false,
    error: "",
    user: {},
};

export const fetchUserById = createAsyncThunk("users/fetchId", async (id: string, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/users/${id}`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const updateUser = createAsyncThunk("users/update", async (data: UserData, thunkApi) => {
    try {
        const id = data.id;
        const dataWithoutId = { ...data };
        delete data.id;
        const config = await tokenHeader();
        const resp = await axios.put(`${baseUrl}/users/${id}`, data, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

const isAnyOf = (...matchers: Array<string | { type: string }>) => (action: AnyAction) =>
    matchers.some((matcher) =>
        typeof matcher === "string" ? matcher === action.type : matcher.type === action.type
    );

export const userSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Fetch user by id
        builder.addCase(fetchUserById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.user = action.payload;
        });

        //Update user
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        builder.addMatcher(isAnyOf(fetchUserById.pending, updateUser.pending), (state) => {
            state.isLoading = true;
        });

        builder.addMatcher(
            isAnyOf(fetchUserById.rejected, updateUser.rejected),
            (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            }
        );
    },
});

export default userSlice;
