import { Theme } from "@mui/material";
import light from "./LightTheme";
import dark from "./DarkTheme";
import { customThemeType } from "./type";

const themes = {
    light,
    dark,
};

export interface getThemeType {
    theme: Theme;
    custom: customThemeType;
}

export default function getTheme(theme: "light" | "dark"): getThemeType {
    return themes[theme];
}
