import { IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import ModalOutline from ".";

const ErrorModal = (props: {
    errorObject: { title?: string; actionText?: string; text?: string };
    open: boolean;
    onClose: any;
}) => {
    return (
        <ModalOutline
            open={props.open}
            title={props.errorObject.title || "Error"}
            submitText={props.errorObject.actionText || "OK"}
            dense
            submitVariant="text"
            onClose={props.onClose}
        >
            <Typography variant="body2">{props.errorObject.text || "An error occured"}</Typography>
        </ModalOutline>
    );
};

export default ErrorModal;
