import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import AddSurgerySteps from "ui/components/AddSurgerySteps";

export interface QrCodeStatusType {
    accepted: boolean;
    used: boolean;
}

const QrCodeScanned: React.FC = () => {
    const [loading, setLoading] = React.useState(true);
    const { qrCode } = useParams<{ qrCode: string }>();
    const [qrCodeStatus, setQrCodeStatus] = React.useState<QrCodeStatusType>({
        accepted: false,
        used: false,
    });

    // Check if qrCode is valid and hasn't been scanned before - API Endpoint Here
    const isQrCodeOkUsingAPI: (qrCode: string) => Promise<QrCodeStatusType> = (qrCode: string) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                const obj = {
                    accepted: true,
                    used: true,
                };
                resolve(obj);
            }, 1000);
        });
    };

    React.useEffect(() => {
        async function checkQrCodeIsOK() {
            const result = await isQrCodeOkUsingAPI(qrCode);
            setLoading(false);
            setQrCodeStatus(result);
        }
        checkQrCodeIsOK();
    }, []);

    return (
        <div>
            {!loading &&
                // You have already claimed this surgery
                (qrCodeStatus.accepted === true ? (
                    <AddSurgerySteps></AddSurgerySteps>
                ) : (
                    <div>Incorrect code</div>
                ))}
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default QrCodeScanned;
