import React, { useState } from "react";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { useHistory } from "react-router-dom";
import { Avatar, Backdrop, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import ShadowButton from "./../components/Utils/ShadowButton";
import lightTheme from "mixins/LightTheme";
import darkTheme from "mixins/DarkTheme";
import VersiusConnectLogo from "ui/resources/icons/versius-logo.png";
import SendVerifyCodeDialog, { dialogCloseState } from "./settings/SendVerifyCodeDialog";
import { useSnackbar } from "notistack";
import Helmet from "react-helmet";

interface FormType {
    email: string;
    password: string;
    confirmPassword: string;
    code: string;
}

const Login = () => {
    const history = useHistory();
    const [userHasCode, setUserHasCode] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [form, setForm] = useState<FormType>({
        email: "",
        password: "",
        confirmPassword: "",
        code: "",
    });

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm((prevState: any) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            };
        });
    };

    const loginUser = async () => {
        setLoading(true);
        if (userHasCode) {
            // Reset password
            setLoading(false);
        } else {
            // Login
            try {
                // "testuserpr@gmail.com", "Duho8343!"
                const user: CognitoUser = await Auth.signIn(form.email, form.password);
                history.push("/logbook");
            } catch (err) {
                enqueueSnackbar(err.message, {
                    variant: "error",
                });
            } finally {
                setLoading(false);
            }
        }
    };
    const handleDialogClose = (type: dialogCloseState) => {
        if (["got-code", "send"].includes(type)) {
            setUserHasCode(true);
        }
        setDialogOpen(false);
    };

    return (
        <>
            <Helmet>
                {/* Scoped style for login page only */}
                <style>
                    {`
                        body {
                            background-image: linear-gradient(45deg, #e6117b, #1885c3);
                        }
                    `}
                </style>
            </Helmet>
            <ThemeProvider theme={darkTheme.theme}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    alignItems={"stretch"}
                    mx={"auto"}
                    maxWidth={400}
                    rowGap={3}
                    height={"calc(100vh - 64px - 96px - 24px)"}
                >
                    <Box display={"flex"} justifyContent="center">
                        <Avatar
                            src={VersiusConnectLogo}
                            alt={"Versius Connect - Logo"}
                            variant="square"
                            sx={{ width: 200, height: "auto" }}
                        ></Avatar>
                    </Box>
                    <Box
                        component={"form"}
                        autoComplete="off"
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        mx={"auto"}
                        rowGap={3}
                        width="100%"
                        onSubmit={(e: any) => {
                            e.preventDefault();
                            loginUser();
                        }}
                    >
                        {userHasCode ? (
                            <TextField
                                type="text"
                                label="Code"
                                name="code"
                                fullWidth
                                variant="filled"
                                autoComplete="off"
                                helperText="Enter code you've recieved on your email"
                                sx={{
                                    "& label": {
                                        color: (theme) => theme.palette.text.primary,
                                    },
                                    "& p": {
                                        color: (theme) => theme.palette.text.disabled,
                                    },
                                }}
                                value={form.code}
                                onChange={(e) => changeHandler(e)}
                            ></TextField>
                        ) : (
                            <TextField
                                type="text"
                                label="Email address"
                                name="email"
                                fullWidth
                                variant="filled"
                                autoComplete="off"
                                sx={{
                                    "& label": {
                                        color: (theme) => theme.palette.text.primary,
                                    },
                                    "& p": {
                                        color: (theme) => theme.palette.text.disabled,
                                    },
                                }}
                                value={form.email}
                                onChange={(e) => changeHandler(e)}
                            ></TextField>
                        )}

                        <TextField
                            type="password"
                            label="Password"
                            helperText={
                                <>
                                    {userHasCode ? (
                                        "Create a new strong password"
                                    ) : (
                                        <Box
                                            sx={{ display: "flex", justifyContent: "flex-end" }}
                                            component="span"
                                        >
                                            <Button
                                                variant="text"
                                                sx={{
                                                    textTransform: "initial",
                                                    color: (theme) => theme.palette.text.disabled,
                                                }}
                                                onClick={() => setDialogOpen(true)}
                                            >
                                                Forgot Password?
                                            </Button>
                                        </Box>
                                    )}
                                </>
                            }
                            name="password"
                            fullWidth
                            variant="filled"
                            autoComplete="new-password"
                            sx={{
                                "& label": {
                                    color: (theme) => theme.palette.text.primary,
                                },
                                "& p": {
                                    color: (theme) => theme.palette.text.disabled,
                                },
                            }}
                            value={form.password}
                            onChange={(e) => changeHandler(e)}
                        ></TextField>
                        {userHasCode && (
                            <TextField
                                type="password"
                                label="Confirm Password"
                                helperText={"Must be same as password"}
                                name="confirmPassword"
                                fullWidth
                                variant="filled"
                                autoComplete="new-password"
                                sx={{
                                    "& label": {
                                        color: (theme) => theme.palette.text.primary,
                                    },
                                    "& p": {
                                        color: (theme) => theme.palette.text.disabled,
                                    },
                                }}
                                value={form.confirmPassword}
                                onChange={(e) => changeHandler(e)}
                            ></TextField>
                        )}
                        <Box mt={1} display="flex" flexDirection={"column"} rowGap={2}>
                            <ThemeProvider theme={lightTheme.theme}>
                                <ShadowButton
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        textTransform: "initial",
                                    }}
                                    fullWidth
                                    onClick={loginUser}
                                >
                                    {userHasCode ? "Reset password" : "Sign in"}
                                </ShadowButton>
                            </ThemeProvider>
                            <Box textAlign={"center"}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        textTransform: "uppercase",
                                        color: (theme) => theme.palette.text.disabled,
                                    }}
                                >
                                    version: 1.1.16 (167) production server
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <ThemeProvider theme={lightTheme.theme}>
                    <SendVerifyCodeDialog open={dialogOpen} onClose={handleDialogClose} />
                </ThemeProvider>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </ThemeProvider>
        </>
    );
};

export default Login;
