import React from "react";
import { usePageTitle } from "hooks";
import {
    Button,
    Card,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Skeleton,
    Theme,
    Typography,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useTheme, ButtonBase } from "@mui/material";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import darkTheme from "mixins/DarkTheme";
import lightTheme from "mixins/LightTheme";

import { CustomThemeContext } from "../../CustomThemeProvider";
import { Link, useHistory } from "react-router-dom";
import { useCmrAuth } from "state/CMRAuth";
import { useSnackbar } from "notistack";
import useIsBreakpoint from "hooks/useIsBreakpoint";

const SettingsPage: React.FC = () => {
    usePageTitle("Settings");
    const isMdAndUp = useIsBreakpoint("md", ">");
    const theme = useTheme();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { currentTheme, setTheme } = React.useContext(CustomThemeContext);
    const listItems = [
        {
            primaryText: "My Profile",
            to: "my-profile",
        },
        {
            primaryText: "Terms & Conditions",
            to: "terms-conditions",
        },
    ];
    const skeletons: {
        theme: Theme;
        themeName: "light" | "dark";
        animation: "pulse" | "wave";
    }[] = [
        {
            theme: lightTheme.theme,
            themeName: "light",
            animation: "pulse",
        },
        {
            theme: darkTheme.theme,
            themeName: "dark",
            animation: "wave",
        },
    ];

    // Logout logic
    const { signOut } = useCmrAuth();
    const logout = async () => {
        try {
            await signOut();
            history.push("/login");
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: "error",
            });
        }
    };
    return (
        <Box
            maxWidth={"sm"}
            mx={"auto"}
            display="flex"
            flexDirection={"column"}
            height="100%"
            minHeight={"calc(100vh - 56px - 76px - 52px)"}
        >
            <Card sx={{ mb: 2 }}>
                <List component="nav">
                    {listItems.map((item, i) => (
                        <ListItemButton key={i} component={Link} to={`/settings/${item.to}`}>
                            <ListItemText primary={item.primaryText} />
                            <ChevronRight color="primary" />
                        </ListItemButton>
                    ))}
                </List>
            </Card>
            <Card sx={{ pb: 2 }}>
                <ListSubheader sx={{ position: "relative" }}>Theme</ListSubheader>
                <Grid container wrap="nowrap" columnGap={3} paddingTop={1} paddingX={2}>
                    {skeletons.map((skeleton, i) => (
                        <ThemeProvider theme={skeleton.theme} key={i}>
                            <Box width={"100%"}>
                                <ButtonBase
                                    sx={{ width: "100%" }}
                                    onClick={() => setTheme(skeleton.themeName)}
                                >
                                    <Card
                                        sx={{
                                            bgcolor: "transparent",
                                            cursor: "pointer",
                                            width: "100%",
                                            border: `3px solid ${
                                                currentTheme === skeleton.themeName
                                                    ? skeleton.theme.palette.primary.main
                                                    : "transparent"
                                            }`,
                                        }}
                                        elevation={0}
                                    >
                                        <Skeleton
                                            variant="rectangular"
                                            animation={skeleton.animation}
                                            height={115}
                                            sx={{
                                                backgroundColor:
                                                    skeleton.theme.palette.background.default,
                                                mb: 1,
                                            }}
                                        />
                                        <Skeleton
                                            animation={skeleton.animation}
                                            sx={{
                                                backgroundColor:
                                                    skeleton.theme.palette.background.default,
                                            }}
                                        />
                                        <Skeleton
                                            width="60%"
                                            animation={skeleton.animation}
                                            sx={{
                                                backgroundColor:
                                                    skeleton.theme.palette.background.default,
                                            }}
                                        />
                                    </Card>
                                </ButtonBase>
                            </Box>
                        </ThemeProvider>
                    ))}
                </Grid>
            </Card>
            <Box sx={{ mt: 2, px: 3, color: theme.palette.text.secondary }}>
                <Typography variant="caption">
                    {/* TODO: Make this info dynamic */}
                    VERSION : 1.1.167 (167) PRODUCTION SERVER
                </Typography>
            </Box>
            {!isMdAndUp && (
                <Box pt={4} pb={4} flexGrow={1} alignItems={"flex-end"} display="flex">
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ alignSelf: "flex-end" }}
                        onClick={() => logout()}
                    >
                        Sign out
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default SettingsPage;
