import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";
import { ThumbnailsI } from "./type";

const name = "thumbnails";

export const initialState = {
    isLoading: false,
    error: "",
    thumbnails: [],
};

export const fetchThumbnails = createAsyncThunk(
    "thumbnails/fetch",
    async (data: ThumbnailsI, thunkApi) => {
        try {
            const config = await tokenHeader(data);
            const resp = await axios.post(
                `${baseUrl}/videos/${data.video_id}/thumbnails/${data.resolution}`,
                data,
                config
            );
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const thumbnailsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchThumbnails.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(fetchThumbnails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.thumbnails = action.payload;
        });

        builder.addCase(fetchThumbnails.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as string;
        });
    },
});

export default thumbnailsSlice;
