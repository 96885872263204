import { Add, BarChart, PlusOne, QrCode, QrCodeScanner } from "@mui/icons-material";
import { Grid, Box, Typography, Button, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import CustomSvgIcon from "ui/components/Utils/CustomSvgIcon";
import { usePageTitle } from "./../../../hooks/index";
import QrCodeIconIOS from "ui/resources/svg/qrcode.svg";
import { Link } from "react-router-dom";

const TrainerPage: React.FC = () => {
    usePageTitle("Trainer");
    return (
        <Grid
            height={"calc(100vh - 185px)"}
            container
            justifyContent={"center"}
            alignItems={"center"}
            maxWidth={"sm"}
            mx={"auto"}
            px={5}
        >
            <Box display={"flex"} flexDirection={"column"} rowGap={2} textAlign={"center"}>
                <Box mb={2}>
                    <CustomSvgIcon size="7" src={QrCodeIconIOS}></CustomSvgIcon>
                </Box>
                <Box>
                    <Typography variant="body1">Your claimed tasks will be shown here.</Typography>
                </Box>
                <Box>
                    <Typography variant="body1">
                        If you&apos;re in the O.R. you can claim your first task now.
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Button
                        component={Link}
                        variant="contained"
                        size="large"
                        color="primary"
                        sx={{ borderRadius: "50%", width: 64, height: 64 }}
                        to="/trainer/scan"
                    >
                        <Add></Add>
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
};

export default TrainerPage;
