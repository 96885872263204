import React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { NavLink } from "react-router-dom";
import CustomSvgIcon from "../Utils/CustomSvgIcon";
import getTheme from "mixins/GetTheme";
import { handleDrawerState } from "state/appSettings";
import { useTypedDispatch, useTypedSelector } from "hooks/store";
import { Box, Button } from "@mui/material";
import { useCmrAuth } from "state/CMRAuth";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled("div")((props: any) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: props?.theme?.spacing(0, 1),
    ...props?.theme?.mixins?.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    })
);

export default function MiniDrawer(props: any) {
    const theme = useTheme();
    const customTheme = getTheme(theme.palette.mode).custom;
    const drawerState = useTypedSelector((state) => state.appSettings.drawerOpen);
    const dispatch = useTypedDispatch();
    const { signOut } = useCmrAuth();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const handleDrawerClose = () => {
        dispatch(handleDrawerState(false));
    };

    const logout = async () => {
        try {
            await signOut();
            history.push("/login");
        } catch (err) {
            enqueueSnackbar(err.message, {
                variant: "error",
            });
        }
    };

    return (
        <>
            <Drawer variant="permanent" open={drawerState}>
                <DrawerHeader>
                    <IconButton onClick={() => handleDrawerClose()}>
                        {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List dense sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    {props?.links?.map(
                        (
                            link: {
                                label: string;
                                to: string;
                                icon: string;
                            },
                            i: number
                        ) => (
                            <ListItem
                                key={i}
                                button
                                component={NavLink}
                                to={link.to}
                                sx={{
                                    py: 1,
                                    pl: 2.7,
                                    "&.active *": {
                                        color: customTheme.primaryText,
                                    },
                                }}
                            >
                                <ListItemIcon>
                                    <CustomSvgIcon
                                        size="1.25"
                                        scale="2"
                                        src={link.icon}
                                    ></CustomSvgIcon>
                                </ListItemIcon>
                                <ListItemText primary={link.label} />
                            </ListItem>
                        )
                    )}
                    {drawerState && (
                        <Box px={3} pb={4} flexGrow={1} alignItems={"flex-end"} display="flex">
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{ alignSelf: "flex-end" }}
                                onClick={() => logout()}
                            >
                                Sign out
                            </Button>
                        </Box>
                    )}
                </List>
            </Drawer>
        </>
    );
}
