import {
    Add,
    ArrowLeft,
    ArrowLeftOutlined,
    ArrowRightAlt,
    ArrowRightOutlined,
    Edit,
    EditOutlined,
    InfoOutlined,
    LocalOfferOutlined,
} from "@mui/icons-material";
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardActionArea,
    Chip,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import CheckOutlinedIOS from "ui/resources/svg/check-outlined.svg";
import EditProcedure, { ProcedureRoleType } from "./Modals/EditProcedure";
import EditTags, { TagType } from "./Modals/EditTags";
import CustomSvgIcon from "./Utils/CustomSvgIcon";

const AddSurgerySteps: React.FC = () => {
    const theme = useTheme();
    const { qrCode } = useParams<{ qrCode: string }>();
    const [loading, setLoading] = React.useState(false);
    const [progress, setProgress] = React.useState(1);
    const [step, setStep] = React.useState(1);
    const [canNavigate, setCanNavigate] = React.useState(false);
    const history = useHistory();
    React.useEffect(() => {
        if (!qrCode) {
            history.push("/trainer");
        }
    }, []);

    // Step 1
    // Type of Claim
    const [typeOfClaim, setTypeOfClaim] = React.useState<string | null>(null);
    const typesOfClaim = [
        {
            id: "dry-lab",
            name: "Dry Lab",
        },
        {
            id: "wet-lab",
            name: "Wet Lab",
        },
        {
            id: "surgery",
            name: "Surgery",
        },
    ];
    // Is responsible surgeon?
    const [isResponsibleSurgeon, setIsResponsibleSurgeon] = React.useState(null);
    const yesNoTypes = [
        {
            id: "yes",
            name: "Yes",
        },
        {
            id: "no",
            name: "No",
        },
    ];
    const [labPurpose, setLabPurpose] = React.useState(null);
    const labPurposes = [
        {
            id: "training",
            name: "Training",
        },
        {
            id: "research",
            name: "Research",
        },
    ];

    // Step 2
    // Procedures
    const [procedures, setProcedures] = React.useState<ProcedureRoleType[]>([]);
    const [editProcedureModal, setEditProcedureModal] = React.useState(false);
    const [
        editProcedureModalObject,
        setEditProcedureModalObject,
    ] = React.useState<ProcedureRoleType | null>(null);

    const addProcedure = (procedure: ProcedureRoleType) => {
        const currentProcedures = procedures;
        if (procedure.index === null) {
            currentProcedures.push(procedure);
        } else {
            currentProcedures[procedure.index] = procedure;
        }
        setProcedures(() => currentProcedures);
    };
    const deleteProcedure = (index: number) => {
        const currentProcedures = procedures;
        if (index >= 0) {
            currentProcedures.splice(index, 1);
            setProcedures(() => currentProcedures);
        }
    };
    const openProcedureModal = (procedure: ProcedureRoleType | null, index: number | null) => {
        setEditProcedureModalObject(procedure ? { ...procedure, index } : null);
        setEditProcedureModal(true);
    };

    // Step 3
    // Add Title
    const [title, setTitle] = React.useState<string | null>(null);

    // Step 4
    // Additional Info
    const [tags, setTags] = React.useState<TagType[]>([]);
    const [tagsModalOpen, setTagsModalOpen] = React.useState(false);
    const deleteTag = (tagToDelete: TagType) => {
        setTags((tags) => tags.filter((tag) => tag.id !== tagToDelete.id));
    };
    const [notes, setNotes] = React.useState("");

    const apiEndPointSimulation = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 2 * 1000);
        });
    };

    // Step 5 ( Finish and send data to API )
    const sendDataToApi = async () => {
        setLoading(true);
        await apiEndPointSimulation();
        setLoading(false);
        history.push("/logbook");
    };

    // Update Progress
    const maxSteps = 4;
    React.useEffect(() => {
        const getProgress = () => {
            // Set default values
            let progress = 1;
            const perc = 25;
            setCanNavigate(false);
            if (typeOfClaim !== null) {
                progress += perc;
                if (typeOfClaim === "surgery") {
                    if (isResponsibleSurgeon) {
                        progress += perc;
                        step === 1 && setCanNavigate(true);
                        if (procedures.length > 0 && step >= 2) {
                            progress += perc;
                            setCanNavigate(true);
                            if (step === 4) {
                                progress += perc;
                            }
                        }
                    }
                } else if (typeOfClaim === "wet-lab") {
                    if (labPurpose) {
                        progress += perc;
                        step === 1 && setCanNavigate(true);
                        if (procedures.length > 0 && step >= 2) {
                            progress += perc;
                            step === 2 && setCanNavigate(true);
                            if (title && title.trim().length > 0 && step >= 3) {
                                setCanNavigate(true);
                                if (step === 4) {
                                    progress += perc;
                                }
                            }
                        }
                    }
                } else if (typeOfClaim === "dry-lab") {
                    if (labPurpose) {
                        progress += perc;
                        step === 1 && setCanNavigate(true);
                        if (title && title.trim().length > 0 && step >= 3) {
                            setCanNavigate(true);
                            if (step === 4) {
                                progress += perc * 2;
                            }
                        }
                    }
                }
            }
            return progress;
        };
        const progress = getProgress();
        setProgress(progress);
    }, [
        step,
        canNavigate,
        typeOfClaim,
        isResponsibleSurgeon,
        labPurpose,
        JSON.stringify(procedures),
        title,
        JSON.stringify(tags),
        notes,
    ]);

    // Navigate between steps
    const goNext = async () => {
        if (step < maxSteps) {
            if (
                (step === 2 && typeOfClaim === "surgery") ||
                (step === 1 && typeOfClaim === "dry-lab")
            ) {
                setStep(step + 2);
            } else {
                setStep(step + 1);
            }
        } else {
            await sendDataToApi();
        }
    };

    const goBack = () => {
        console.log(step);
        if (
            (step === 4 && typeOfClaim === "surgery") ||
            (step === 3 && typeOfClaim === "dry-lab")
        ) {
            setStep(step - 2);
        } else {
            setStep(step - 1);
        }
    };

    return (
        <Box
            display={"flex"}
            maxWidth={"sm"}
            mx={"auto"}
            flexDirection={"column"}
            rowGap={5}
            pt={2}
        >
            {/* Code Scanned */}
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} columnGap={2}>
                <CustomSvgIcon size="3" src={CheckOutlinedIOS}></CustomSvgIcon>
                <Typography variant="h6">Code scanned</Typography>
            </Box>
            <Box>
                <Card
                    sx={{
                        height: "34px",
                        bgcolor: "transparent",
                        backgroundImage:
                            "linear-gradient(to right, #e50c78, #750db3, #1761c9, #14afd3) !important",
                        p: "3px",
                        borderRadius: 8,
                    }}
                    elevation={0}
                >
                    <Card
                        sx={{
                            height: "100%",
                            borderRadius: 8,
                            p: "6px",
                            bgcolor: theme.palette.background.default,
                        }}
                        elevation={0}
                    >
                        <Card
                            sx={{
                                height: "100%",
                                borderRadius: 8,
                                bgcolor: "transparent",
                                backgroundImage:
                                    "linear-gradient(to right, #e50c78, #750db3, #1761c9, #14afd3) !important",
                                display: "flex",
                                justifyContent: "flex-end",
                                p: 0,
                            }}
                            elevation={0}
                        >
                            <Box
                                width={`${100 - (progress - 1)}%`}
                                height={"100%"}
                                bgcolor={theme.palette.background.default}
                                sx={{ transition: "width 0.25s linear" }}
                            ></Box>
                        </Card>
                    </Card>
                </Card>
            </Box>

            {step === 1 && (
                <>
                    {/* Step 1 - Type of Claim */}
                    <Box display={"flex"} rowGap={2} flexDirection={"column"}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Please select type of Claim
                        </Typography>
                        <Box>
                            <ToggleButtonGroup
                                size="large"
                                color="primary"
                                value={typeOfClaim}
                                exclusive
                                onChange={(e, v) => setTypeOfClaim(v)}
                            >
                                {typesOfClaim.map((type, i) => (
                                    <ToggleButton
                                        key={i}
                                        sx={{ textTransform: "initial", px: 3 }}
                                        size="large"
                                        value={type.id}
                                    >
                                        {type.name}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    {/* Start Case 1 - Surgery */}
                    {typeOfClaim === "surgery" && (
                        <>
                            <Box display={"flex"} rowGap={2} flexDirection={"column"}>
                                {/* Is responsible surgeon? */}
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Are you the responsible surgeon*?
                                </Typography>
                                <Box rowGap={1} display={"flex"} flexDirection={"column"}>
                                    <Box>
                                        <ToggleButtonGroup
                                            size="large"
                                            color="primary"
                                            value={isResponsibleSurgeon}
                                            exclusive
                                            onChange={(e, v) => setIsResponsibleSurgeon(v)}
                                        >
                                            {yesNoTypes.map((type, i) => (
                                                <ToggleButton
                                                    key={i}
                                                    sx={{ textTransform: "initial", px: 3 }}
                                                    size="large"
                                                    value={type.id}
                                                >
                                                    {type.name}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Box>
                                    <Typography variant="caption" maxWidth={250}>
                                        You are responsible surgeon if you are responsible for the
                                        patient
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                    {/* ../End Case 1 - Surgery */}
                    {/* Start Case 2 - ( Wet Lab or Dry Lab ) */}
                    {typeOfClaim && typeOfClaim !== "surgery" && (
                        <>
                            <Box display={"flex"} rowGap={2} flexDirection={"column"}>
                                {/* What was the purpose? */}
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    What was the purpose?
                                </Typography>
                                <Box>
                                    <ToggleButtonGroup
                                        size="large"
                                        color="primary"
                                        value={labPurpose}
                                        exclusive
                                        onChange={(e, v) => setLabPurpose(v)}
                                    >
                                        {labPurposes.map((type, i) => (
                                            <ToggleButton
                                                key={i}
                                                sx={{ textTransform: "initial", px: 3 }}
                                                size="large"
                                                value={type.id}
                                            >
                                                {type.name}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </Box>
                            </Box>
                        </>
                    )}
                    {/* ../End Case 2 - ( Wet Lab or Dry Lab ) */}
                </>
            )}

            {step === 2 && (
                <>
                    {/* Step 2 - Add Procedures */}
                    <Box display={"flex"} rowGap={2} flexDirection={"column"}>
                        <Box>
                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                Add Procedures
                            </Typography>
                            <Typography variant="body2">
                                At least one procedure is required
                            </Typography>
                        </Box>
                        <Box>
                            <List sx={{ rowGap: 2, display: "flex", flexDirection: "column" }}>
                                <Card
                                    sx={{
                                        bgcolor: theme.palette.primary.main,
                                        color: "white",
                                    }}
                                >
                                    <CardActionArea onClick={() => openProcedureModal(null, null)}>
                                        <ListItem>
                                            <ListItemIcon sx={{ color: "inherit" }}>
                                                <Add></Add>
                                            </ListItemIcon>
                                            <ListItemText
                                                secondaryTypographyProps={{
                                                    sx: { color: "#F4F4F4" },
                                                }}
                                                primary="Add procedure"
                                                secondary="Click to add a new procedure"
                                            />
                                        </ListItem>
                                    </CardActionArea>
                                </Card>
                                {procedures.map((proc, i) => (
                                    <>
                                        {i < 2 && (
                                            <ListSubheader
                                                sx={{
                                                    textAlign: "start",
                                                }}
                                                className="no-sticky"
                                            >
                                                {i === 0
                                                    ? "Primary procedure"
                                                    : "Secondary procedures"}
                                            </ListSubheader>
                                        )}
                                        <Card key={proc.procedure.id}>
                                            <CardActionArea
                                                onClick={() => openProcedureModal(proc, i)}
                                            >
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Typography
                                                            sx={{ width: 24, textAlign: "center" }}
                                                        >
                                                            {i + 1}
                                                        </Typography>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={proc.procedure.name}
                                                        secondary={`My role: ${proc.role.name}`}
                                                    />
                                                    <ListItemIcon
                                                        sx={{ justifyContent: "flex-end" }}
                                                    >
                                                        <EditOutlined></EditOutlined>
                                                    </ListItemIcon>
                                                </ListItem>
                                            </CardActionArea>
                                        </Card>
                                    </>
                                ))}
                            </List>
                        </Box>
                    </Box>
                    {/* ../End Case 1 - Surgery */}
                </>
            )}

            {step === 3 && (
                <>
                    {/* Step 3 - Add Title */}
                    <Box display={"flex"} rowGap={2} flexDirection={"column"}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                            Add title
                        </Typography>
                        <Box>
                            <TextField
                                variant="outlined"
                                label="Title"
                                helperText={`Add title for the ${typeOfClaim
                                    ?.toLowerCase()
                                    .replace("-", " ")}`}
                                fullWidth
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            ></TextField>
                        </Box>
                    </Box>
                </>
            )}

            {step === 4 && (
                <>
                    {/* Step 4 - Additional Info */}
                    <Box display={"flex"} rowGap={4} flexDirection={"column"}>
                        <Box display={"flex"} flexDirection={"column"} rowGap={1}>
                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                Additional Info
                            </Typography>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography variant="body2">
                                    Add your tags and notes to help identify the surgery.
                                </Typography>
                                <Typography variant="body2">
                                    They won&apos;t be shared with other surgery participants.
                                </Typography>
                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} rowGap={2}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Tags
                                </Typography>
                                <Button
                                    disableElevation
                                    sx={{ columnGap: 1, textTransform: "initial" }}
                                    onClick={() => setTagsModalOpen(true)}
                                >
                                    <EditOutlined></EditOutlined>
                                    Add or remove tags
                                </Button>
                            </Box>
                            {tags.length > 0 ? (
                                <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                                    {tags.map((tag, i) => (
                                        <Chip
                                            key={i}
                                            label={tag.name}
                                            variant="outlined"
                                            sx={{ px: 1 }}
                                            onDelete={() => deleteTag(tag)}
                                        ></Chip>
                                    ))}
                                </Box>
                            ) : (
                                <Paper elevation={0} sx={{ py: 2 }}>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        px={2}
                                    >
                                        <Typography variant="overline">No Tags</Typography>
                                        <Typography variant="body2">
                                            Added tags will be displayed here
                                        </Typography>
                                    </Box>
                                </Paper>
                            )}
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} rowGap={1}>
                            <Box display={"flex"}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Notes
                                </Typography>
                            </Box>
                            <Box display={"flex"}>
                                <TextField
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    multiline
                                    variant="outlined"
                                    placeholder="Write your notes..."
                                    fullWidth
                                    minRows={3}
                                    helperText={
                                        <>
                                            <Box
                                                display={"flex"}
                                                mt={1}
                                                columnGap={1}
                                                flexWrap={"wrap"}
                                            >
                                                <Box>
                                                    <InfoOutlined fontSize="small"></InfoOutlined>
                                                </Box>
                                                <Box>
                                                    Write or dictate info about the surgery. <br />{" "}
                                                    No patient data.
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                ></TextField>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            {/* Start Step controller */}
            {canNavigate && (
                <Box columnGap={1} display={"flex"} justifyContent={"flex-end"}>
                    <Button disabled={step === 1} variant="outlined" onClick={() => goBack()}>
                        <ArrowRightAlt sx={{ mr: 1, transform: "rotate(180deg)" }}></ArrowRightAlt>
                        Back
                    </Button>
                    <Button variant="outlined" onClick={() => goNext()}>
                        Next
                        <ArrowRightAlt sx={{ ml: 1 }}></ArrowRightAlt>
                    </Button>
                </Box>
            )}
            {/* ../End Step controller */}

            {/* Start Modals */}
            <EditProcedure
                procedureObject={editProcedureModalObject}
                open={editProcedureModal}
                onClose={() => setEditProcedureModal(false)}
                onSubmit={(procedure: any) => addProcedure(procedure)}
                onDelete={(index: number) => deleteProcedure(index)}
            ></EditProcedure>
            <EditTags
                tagsObject={{ id: null, tags: tags.map((tag) => tag.id) }}
                open={tagsModalOpen}
                onClose={() => setTagsModalOpen(false)}
                onSubmit={(tags: TagType[]) => setTags(tags)}
            ></EditTags>
            {/* ../End Modals */}
            {/* Use Loading Screen */}
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

export default AddSurgerySteps;
