import Auth, { CognitoUser } from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import { AxiosInstance } from "axios";
import { useSelector } from "react-redux";
import { Store } from "redux";
import { createAsyncAction, createReducer, ActionType, createAction } from "typesafe-actions";
import { DeepReadonly } from "utility-types";

export type CognitoConfig = {
    appClientId: string;
    domain: string;
    region: string;
    userPoolId: string;
};

export type User = {
    email: string;
    groups: string[];

    name: string;
    forename?: string;
    surname?: string;

    username: string;
    cognitoSub: string;
};

const actions = {
    getUser: createAsyncAction(
        "CMRAuth/GET_USER/REQUEST",
        "CMRAuth/GET_USER/SUCCESS",
        "CMRAuth/GET_USER/FAILURE"
    )<undefined, User, string>(),
    redirectSignIn: createAction("CMRAuth/REDIRECT/SIGN_IN")<undefined>(),
    redirectSignOut: createAction("CMRAuth/REDIRECT/SIGN_OUT")(),
};

export type CMRAuthState = DeepReadonly<{
    isLoading: boolean;
    user?: User;
}>;
type CMRAuthAction = ActionType<typeof actions>;

const initialState: CMRAuthState = {
    user: undefined,
    // Initially loading as we asynchronously check for a signed-in user on app load
    isLoading: true,
};

// We know at this point that the store is not undefined
/* eslint-disable @typescript-eslint/no-non-null-assertion */
class _CMRAuth {
    private store?: Store;

    configure(store: Store, cognitoConfig: CognitoConfig): void {
        if (this.store) throw new Error("Already configured");

        this.store = store;

        const { region, userPoolId, appClientId, domain } = cognitoConfig;
        const host = window.location.origin;
        Amplify.configure({
            Auth: {
                mandatorySignIn: true,
                region,
                userPoolId,
                userPoolWebClientId: appClientId,
                oauth: {
                    domain,
                    scope: ["email", "profile", "openid"],
                    redirectSignIn: host,
                    redirectSignOut: host,
                    responseType: "code",
                },
            },
        });

        // TODO see below
        // Hub.listen("auth", this.handleHubEvent.bind(this));

        this.checkUser();
    }

    private checkConfiguration(): void {
        if (!this.store) throw new Error("CMRAuth is not configured");
    }

    // TODO consider adding additional actions for the sake of tracing log in issues
    // private handleHubEvent({ payload }: HubCapsule): void {
    //     // We know at this point that the store is not undefined
    //     switch (payload.event) {
    //         case "signIn":
    //             this.store!.dispatch(actions.logIn.success());
    //             break;
    //         default:
    //     }
    // }

    private async checkUser(): Promise<void> {
        this.checkConfiguration();

        try {
            const user: CognitoUser = await Auth.currentAuthenticatedUser();
            const userData = user.getSignInUserSession()!.getIdToken().payload;
            const groups: string[] = userData["cognito:groups"] || [];

            console.log(user, "check user");

            // TODO make this common + parameterized
            const isCmr = groups.some((g) => g.startsWith(`cmr.`));
            // Change after test to !isCmr
            if (isCmr) {
                // eslint-disable-next-line no-alert
                // alert("Only CMR employees have access to this app");
                // user.signOut();
                // throw new Error("User is not a CMR employee");
            } else {
                this.store!.dispatch(
                    actions.getUser.success({
                        email: userData.email,

                        groups,

                        name: userData.name,
                        forename: userData.given_name,
                        surname: userData.family_name,

                        username: user.getUsername(),
                        cognitoSub: userData.sub,
                    })
                );
            }
        } catch (err) {
            this.store!.dispatch(actions.getUser.failure(err.message));
        }
    }

    /**
     * Redirects the browser to the Auth0 sign-in page, allowing the user to sign in using their corporate credentials.
     */
    signIn(): void {
        this.checkConfiguration();
        this.store!.dispatch(actions.redirectSignIn());
        // 'as never' because aws-amplify types are lacking here in that they don't allow custom IDP names (even though the API does)
        Auth.federatedSignIn({ provider: "Auth0" } as never);
    }

    /**
     * Signs the user out of the application then redirects the browser to the Auth0 sign-out page to clear Auth0 session cookies.
     */
    signOut(): void {
        this.checkConfiguration();
        this.store!.dispatch(actions.redirectSignOut());
        Auth.signOut();
    }

    /**
     * Obtains the access token from the current session.
     *
     * If the session is expired, it's automatically refreshed if a valid refresh token is available.
     */
    getAccessToken(): Promise<string | null> {
        this.checkConfiguration();
        // currentSession() will automatically refresh the current access/ID tokens if possible (valid refresh token)
        return Auth.currentSession()
            .then((a) => a.getAccessToken().getJwtToken())
            .catch(() => null);
    }

    /**
     * Adds an interceptor to the provided axios instance that automatically picks up and adds the
     * access token from the current session to each HTTP request.
     *
     * If the token is expired, it's automatically refreshed if a valid refresh token is available.
     *
     * @example
     * ...
     * const someApi = axios.create({ baseUrl: "http://some.base.url" });
     * CMRAuth.addAxiosInterceptor(someApi);
     *
     * const res = await someApi.get("/items");
     * ...
     *
     */
    addAxiosInterceptor(axiosInstance: AxiosInstance): void {
        axiosInstance.interceptors.request.use(
            async (config) => {
                const accessToken = await this.getAccessToken();
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
                return config;
            },
            (error) => Promise.reject(error)
        );
    }

    // eslint-disable-next-line class-methods-use-this
    getReducer() {
        return createReducer<CMRAuthState, CMRAuthAction>(initialState)
            .handleAction(actions.getUser.success, (state, action) => ({
                ...state,
                user: action.payload,
                isLoading: false,
            }))
            .handleAction(actions.getUser.failure, (state) => ({
                ...state,
                user: undefined,
                isLoading: false,
            }));
    }
}
/* eslint-enable @typescript-eslint/no-non-null-assertion */

/**
 * A singleton responsible for the state of CMR Auth.
 *
 * Must be configured before use.
 *
 * @example
 * ...
 * const rootReducer = combineReducers({
 *     ...
 *     // Set up auth reducer
 *     auth: CMRAuth.getReducer(),
 *     ...
 * });
 * ...
 * const store = createStore(...);
 * // Configure CMRAuth and subscribe it to the Redux store
 * CMRAuth.configure(store, COGNITO_CONFIG);
 */
const CMRAuth = new _CMRAuth();

/**
 * A React hook providing easy access to CMRAuth in Reach function components.
 *
 * @requires
 * + CMRAuth to have been linked to the Redux store
 * + Component to be a descendent of a Redux provider
 */
export const useCmrAuth = (): CMRAuthState & {
    signIn: () => void;
    signOut: () => void;
} => {
    const authState = useSelector(
        (state: { CMRAuth: CMRAuthState; [x: string]: unknown }) => state.CMRAuth
    );
    return {
        ...authState,
        signIn: () => CMRAuth.signIn(),
        signOut: () => CMRAuth.signOut(),
    };
};

export default CMRAuth;
