import React from "react";
import { alpha, Button, ButtonTypeMap, ExtendButtonBase, useTheme } from "@mui/material";
type buttonColorType = "primary" | "secondary" | "success" | "error" | "info" | "warning";
const buttonColorTypes = ["primary", "secondary", "success", "error", "info", "warning"];
const ShadowButton: ExtendButtonBase<ButtonTypeMap> = (props: any) => {
    let mounted = true;
    const theme = useTheme();
    const [color, setColor] = React.useState<buttonColorType>(props.color || "primary");
    React.useEffect(() => {
        if (mounted && props?.variant === "contained" && props?.color) {
            setColor(props.color);
        }
        return () => {
            mounted = false;
        };
    }, [color, props.color, props.variant]);
    const alphaValue = theme.palette.mode === "dark" ? "7px" : "10px";
    const mainColor = !buttonColorTypes.includes(color) ? "inherit" : theme.palette[color].main;
    const darkColor = !buttonColorTypes.includes(color) ? "inherit" : theme.palette[color].dark;
    const canHaveShadow = !props.variant || props.variant === "contained";
    const sx = {
        ...props?.sx,
        boxShadow: canHaveShadow ? `0 0 ${alphaValue} 0 ${mainColor}` : "",
        "&:hover": {
            boxShadow: canHaveShadow ? `0 0 ${alphaValue} 0 ${darkColor}` : "",
        },
    };
    return <Button {...props} sx={sx}></Button>;
};

export default ShadowButton;
