/*
    This file is used to extract complexities away from the main UI code, like linking to the Redux
    store and theming common components.
*/

import React from "react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import CustomThemeProvider from "./CustomThemeProvider";

import store from "state/store";
import App from "ui/App";

const AppWrapper: React.FC = () => {
    return (
        <React.StrictMode>
            <Provider store={store}>
                <CustomThemeProvider>
                    <SnackbarProvider maxSnack={3}>
                        <App />
                    </SnackbarProvider>
                </CustomThemeProvider>
            </Provider>
        </React.StrictMode>
    );
};
export default AppWrapper;
