import React from "react";
import useBreakpoint from "use-breakpoint";
const BREAKPOINTS: { xs: number; sm: number; md: number; lg: number; xl: number } = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
};
const useIsBreakpoint = (
    bp: "xs" | "sm" | "md" | "lg" | "xl",
    direction?: ">" | "<" | "="
): boolean => {
    let active = true;

    const [value, setValue] = React.useState(false);
    const { breakpoint } = useBreakpoint(BREAKPOINTS, bp, false);

    const getValue = (cbp?: typeof bp): boolean => {
        const bpToUse = cbp || breakpoint;
        if (
            bpToUse === bp ||
            (direction === ">" && BREAKPOINTS[bpToUse] > BREAKPOINTS[bp]) ||
            (direction === "<" && BREAKPOINTS[bpToUse] < BREAKPOINTS[bp])
        ) {
            return true;
        } else {
            return false;
        }
    };

    function getCurrentBp(): typeof bp {
        let v = bp;
        const width = window.innerWidth;
        if (width >= BREAKPOINTS.xl) {
            v = "xl";
        } else if (width >= BREAKPOINTS.lg) {
            v = "lg";
        } else if (width >= BREAKPOINTS.md) {
            v = "md";
        } else if (width >= BREAKPOINTS.sm) {
            v = "sm";
        } else {
            v = "xs";
        }
        return v;
    }

    React.useEffect(() => {
        if (active) {
            const v = getValue();
            setValue(v);
            const handleResize = () => {
                if (active) {
                    const cbp = getCurrentBp();
                    const localV = getValue(cbp);
                    setValue(localV);
                }
            };
            window.addEventListener("resize", handleResize);
        }
        return () => {
            active = false;
        };
    }, [bp, direction]);

    return value;
};

export default useIsBreakpoint;
