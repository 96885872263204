import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    ListItemText,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { ChevronRight } from "@mui/icons-material";
import { TransitionGroup, CSSTransition, SwitchTransition } from "react-transition-group";
import ShadowButton from "../../components/Utils/ShadowButton";

export interface SimpleDialogProps {
    open: boolean;
    onClose: (value: string) => void;
}

export type dialogCloseState = "cancel" | "send" | "got-code" | string;

const SlideTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SendVerifyCodeDialog = (props: SimpleDialogProps) => {
    let componentMounted = true;
    const [step, setStep] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const { onClose, open } = props;
    const handleClose = async (type: dialogCloseState) => {
        if (type === "send") {
            setIsLoading(true);
            const requestCode = () => {
                // TODO Request a code from API
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(true);
                    }, 1000);
                });
            };
            await requestCode();
            onClose(type);
        } else {
            onClose(type);
        }
    };
    React.useEffect(() => {
        if (open === false && componentMounted) {
            setStep(0);
        }
        return () => {
            componentMounted = false;
        };
    }, [open]);
    return (
        <Dialog
            open={open}
            scroll="paper"
            TransitionComponent={SlideTransition}
            maxWidth="xs"
            sx={{
                "& .MuiPaper-root": {
                    overflowX: "hidden",
                },
            }}
            onClose={() => handleClose("cancel")}
        >
            <DialogTitle sx={{ pt: 3 }}>Forgot Password?</DialogTitle>
            <DialogContent sx={{ overflowX: "hidden" }}>
                <TransitionGroup>
                    <SwitchTransition mode="out-in">
                        <CSSTransition timeout={300} classNames="tabs" key={step}>
                            {step === 0 ? (
                                <Box>
                                    <List>
                                        <ListItemButton onClick={() => setStep(1)}>
                                            <ListItemText
                                                primary="Request a code"
                                                secondary="We will send a verification code to your registered email address."
                                            ></ListItemText>
                                            <ChevronRight></ChevronRight>
                                        </ListItemButton>
                                        <ListItemButton onClick={() => handleClose("got-code")}>
                                            <ListItemText
                                                primary="I have a code"
                                                secondary="If you've recieved a verification code, you can reset your password from here."
                                            ></ListItemText>
                                            <ChevronRight></ChevronRight>
                                        </ListItemButton>
                                    </List>
                                </Box>
                            ) : (
                                <Box>
                                    <Box paddingTop={1}>
                                        <TextField
                                            variant="outlined"
                                            label="Email address"
                                            helperText="We will send a reset password verification code to your registered email address."
                                        ></TextField>
                                    </Box>
                                </Box>
                            )}
                        </CSSTransition>
                    </SwitchTransition>
                </TransitionGroup>
            </DialogContent>
            <CSSTransition in={step === 1} timeout={600} classNames="tabs" unmountOnExit>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                    <Button
                        variant="text"
                        size="large"
                        sx={{ textTransform: "initial" }}
                        onClick={() => handleClose("cancel")}
                    >
                        Cancel
                    </Button>
                    <ShadowButton
                        variant="contained"
                        size="large"
                        sx={{ textTransform: "initial" }}
                        onClick={() => handleClose("send")}
                    >
                        Send
                    </ShadowButton>
                </DialogActions>
            </CSSTransition>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
};

export default SendVerifyCodeDialog;
