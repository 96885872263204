import React from "react";
import { Toolbar, IconButton, Typography, styled, Box, TextField } from "@mui/material";
import { useTypedSelector, useTypedDispatch } from "hooks/store";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import useIsBreakpoint from "hooks/useIsBreakpoint";
import { handleDrawerState, updateSearchQuery } from "state/appSettings";
import { Add, Search, Share, ChevronLeft, ArrowLeft, KeyboardBackspace } from "@mui/icons-material";
import darkTheme from "mixins/DarkTheme";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    drawerbreakpoint?: string;
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, drawerbreakpoint }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerbreakpoint === "true" ? drawerWidth : 0,
        width: `calc(100% - ${drawerbreakpoint === "true" ? drawerWidth : 0}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export type Props = {
    children?: React.ReactNode;
};

const AppHeader: React.FC<Props> = ({ children }) => {
    const drawerState = useTypedSelector((state) => state.appSettings.drawerOpen);
    const searchQuery = useTypedSelector((state) => state.appSettings.searchQuery);
    const pageTitle = useTypedSelector((state) => state.appSettings.pageTitle);
    const isMdAndUp = useIsBreakpoint("md", ">");
    const dispatch = useTypedDispatch();
    const history = useHistory();
    const location = useLocation();
    const [searchFocus, setSearchFocus] = React.useState(false);
    const [canGoBack, setCanGoBack] = React.useState(false);

    const setSearchQuery = (str: string) => {
        dispatch(updateSearchQuery(str));
    };

    const openDrawer = () => {
        setSearchQuery("");
        dispatch(handleDrawerState(true));
    };

    const handleBackButton = () => {
        if (searchFocus) {
            setSearchFocus(false);
        } else {
            if (history.length >= 1) {
                history.go(-1);
            } else {
                history.push("/logbook");
            }
        }
    };

    // Watchers
    React.useEffect(() => {
        let v = false;
        if (searchFocus) {
            v = true;
        } else {
            setSearchQuery("");
        }
        if (location.pathname.replace("/", "").includes("/")) {
            v = true;
        }
        setCanGoBack(v);
    }, [location.pathname, searchFocus]);

    // Watcher for `searchQuery`
    React.useEffect(() => {
        setSearchFocus(false);
        setSearchQuery("");
    }, [location.pathname]);

    return (
        <AppBar
            position="fixed"
            sx={{ backgroundImage: "initial" }}
            color="secondary"
            open={drawerState}
            drawerbreakpoint={`${isMdAndUp}`}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => openDrawer()}
                    edge="start"
                    sx={{
                        marginRight: "36px",
                        marginLeft: "-8px",
                        ...(drawerState && { display: "none" }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                {canGoBack && (
                    <IconButton
                        sx={{ marginRight: "24px", marginLeft: "-8px" }}
                        onClick={() => handleBackButton()}
                    >
                        <KeyboardBackspace />
                    </IconButton>
                )}
                {!searchFocus && (
                    <Typography variant="h6" noWrap component="div">
                        {pageTitle}
                    </Typography>
                )}
                <Box
                    flexGrow={1}
                    display="flex"
                    justifyContent="flex-end"
                    height={"100%"}
                    alignItems={"center"}
                >
                    {searchFocus ? (
                        <TextField
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            autoFocus
                            variant="standard"
                            fullWidth
                            placeholder="Search..."
                        ></TextField>
                    ) : (
                        <>
                            {["/logbook", "/video-library"].includes(location.pathname) && (
                                <IconButton color="primary" onClick={() => setSearchFocus(true)}>
                                    <Search></Search>
                                </IconButton>
                            )}
                            {location.pathname === "/logbook" && (
                                <IconButton
                                    color="primary"
                                    onClick={() => history.push("/trainer/scan")}
                                >
                                    <Add></Add>
                                </IconButton>
                            )}
                            {location.pathname === "/stats" && (
                                <IconButton color="primary">
                                    <Share></Share>
                                </IconButton>
                            )}
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default AppHeader;
