import { AnyAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl, tokenHeader } from "../../config";
import { CreateBookmarkI } from "./types";

const name = "tags";

export const initialState = {
    isLoading: false,
    error: "",
    tags: [],
};

export const fetchTags = createAsyncThunk("tags/fetch", async (_, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.get(`${baseUrl}/tags`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.message);
    }
});

export const createTags = createAsyncThunk(
    "tags/create",
    async (data: CreateBookmarkI, thunkApi) => {
        try {
            const config = await tokenHeader();
            const resp = await axios.post(`${baseUrl}/tags`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

export const deleteTags = createAsyncThunk("tags/delete", async (id: string, thunkApi) => {
    try {
        const config = await tokenHeader();
        const resp = await axios.delete(`${baseUrl}/tags/${id}`, config);
        return resp.data;
    } catch (e) {
        return thunkApi.rejectWithValue(e.response.data.detail);
    }
});

export const updateTags = createAsyncThunk(
    "tags/update",
    async (data: CreateBookmarkI, thunkApi) => {
        try {
            const id = data.bookmarkId;
            delete data.bookmarkId;
            const config = await tokenHeader();
            const resp = await axios.put(`${baseUrl}/tags/${id}`, data, config);
            return resp.data;
        } catch (e) {
            return thunkApi.rejectWithValue(e.message);
        }
    }
);

const isAnyOf = (...matchers: Array<string | { type: string }>) => (action: AnyAction) =>
    matchers.some((matcher) =>
        typeof matcher === "string" ? matcher === action.type : matcher.type === action.type
    );

const tagsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Create tags
        builder.addCase(createTags.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.bookmarks = [action.payload, ...state.bookmarks];
            console.log(action.payload, "action.payload");
        });

        //Fetch tags
        builder.addCase(fetchTags.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tags = action.payload;
        });

        //Delete tag
        builder.addCase(deleteTags.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.bookmark = action.payload;
        });

        //Update tag
        builder.addCase(updateTags.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.bookmark = action.payload;
        });

        builder.addMatcher(
            isAnyOf(createTags.pending, fetchTags.pending, deleteTags.pending, updateTags.pending),
            (state) => {
                state.isLoading = true;
            }
        );

        builder.addMatcher(
            isAnyOf(
                createTags.rejected,
                fetchTags.rejected,
                deleteTags.rejected,
                updateTags.rejected
            ),
            (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string;
            }
        );
    },
});

export default tagsSlice;
